import readXlsxFile, { readSheetNames } from 'read-excel-file'
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import * as Yup from 'yup';

import { Form, Formik, Field } from "formik";
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  FormGroup, Input, Label, FormFeedback, Button

} from 'reactstrap';
import {classiConcorso,getClasseConcorsoByCode } from "../pages/constants"
import schoolsDict from '../schools/schools_2022_23_v3.json'

// fonti:
// http://www.sardegna.istruzione.it/elenco_scuole.shtml
// https://react-hook-form.com/get-started#Handleerrors
//https://stackoverflow.com/questions/66927729/ref-register-inside-input-is-giving-me-a-path-split-error


export const SchoolSubjectPicker = (props) => {
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const [selectedSchoolSubject, setSelectedSchoolSubject] = useState(null);
  //console.log("DEFAULT VALUE:", props.defaultValue, getClasseConcorsoByCode(props.defaultValue));
return (
  <Autocomplete
  defaultValue={getClasseConcorsoByCode(props.defaultValue)}
  onChange={(event, newValue) => {
    //console.log("SSP onChange newValue:", newValue)
    props.onChange(newValue?.code);
  }}
  selectOnFocus
  clearOnBlur
  handleHomeEndKeys
  id="school-subject"
  options={classiConcorso}
  fullWidth
  renderInput={(params) => <TextField {...params} label={t("Inserisci qui il nome della tua materia")} />}
  renderOption={(props, option) => <li {...props}>{!option["code"] ? `N.A` : `(${option["code"]?.toUpperCase()}) ${option["description"]}`}</li>}
  getOptionLabel={(option) => {return (!option["code"] ? `N.A` : `(${option["code"]?.toUpperCase()}) ${option["description"]}`)}}
/>)

}

export const SchoolPicker = (props) => {

  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);

  const checkKeyDown = (e) => {
    if  ((e.charCode || e.keyCode) === 13) e.preventDefault();
  };



  const { t, i18n } = useTranslation('frontend', { useSuspense: false });

  const map = {
    "Provincia": "provincia", "Comune": "comune", "IST.": "tipo",
    "Denominazione": "istituto", "Codice": "codice"
  }

  const [value, setValue] = useState(props.initialValue || "");
  const[newSchoolName, setNewSchoolName] = useState("")
  const [open, toggleOpen] = useState(false);

  const filter = createFilterOptions({"limit":300});

  const handleClose = () => {
    setDialogValue({
      codice: '',
      istituto: '',
      tipo: '',
      comune: ''
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = useState({
    codice: '',
    istituto: '',
    tipo: '',
    comune: ''
  });

  const SchoolSchema = Yup.object().shape({
    codice: Yup.string()
      .length(10, t("lunghezza non valida"))
      .required(t('Campo obbligatorio')),

    istituto: Yup.string()
      .min(2, t('Campo troppo corto!'))
      .max(50, t('Campo troppo lungo!'))
      .required(t('Campo obbligatorio')),

    tipo: Yup.string()
      .min(2, t('Campo troppo corto!'))
      .max(20, t('Campo troppo lungo!'))
      .required(t('Campo obbligatorio')),

    comune: Yup.string()
      .min(2, t('Campo troppo corto!'))
      .max(20, t('Campo troppo lungo!'))
      .required(t('Campo obbligatorio'))
  });



  useEffect(() => {

    const readExcelFile = async () => {
      let content = []
      try {
        const url = "/schools/schools_SARDEGNA.xlsx"

        const response = await fetch(url);
        const blob = await response.blob();
        const sheetNames = await readSheetNames(blob)

        for (let i = 0; i < sheetNames.length; i++) {
          //console.log("EXCEL SHEETNAME:", sheetNames[i])
          const rows = await readXlsxFile(blob, { map, sheet: sheetNames[i] });
          content.push(...rows["rows"])
          //console.log(`EXCEL rows:`, rows)
        }

        //console.log("EXCEL content:", content);
        setSchools(content);

      } catch (error) {
        //console.log("EXCEL read error:", error)
      }
    }
    //console.log("SCHOOL MANAGER USEEFFECT[]->", schoolsDict)
    
    let allSchools = Object.values(schoolsDict).map(school => {
      return {"codice" : school["codice_meccanografico"],
              "comune" : school["comune"],
              "istituto" : school["nome_istituto_riferimento"],
              "provincia" : school["provincia"],
              "tipo" : school["tipo"]}
    })
     setSchools(allSchools);
     setValue(props.initialValue || "")
     setSelectedSchool(props.initialValue || "");
     // readExcelFile();

  }, [])


  useEffect(() => {
    //console.log("Schools loaded:", schools)
  },
    [schools])

  useEffect(() => {
    //console.log("Selected School:", selectedSchool)
    props.onChange(selectedSchool);
  },
    [selectedSchool])


  return <div>
    <Autocomplete
      value={value}
      disabled={props.disabled}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          // timeout to avoid instant validation of the dialog's form.
          setTimeout(() => {
            toggleOpen(true);
            setDialogValue({
              istituto: newValue,
              codice: '',
              tipo: '',
              comune: ''
            });
          });
        } else if (newValue && newValue.inputValue) {
          toggleOpen(true);
          setDialogValue({
            istituto: newValue.inputValue,
            codice: '',
            tipo: '',
            comune: ''
          });
        } else {
          setValue(newValue);
          setSelectedSchool(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        //console.log(`FILTERED inputValue:${options.length}`, params);

        if (params.inputValue !== '') {
          setNewSchoolName(params.inputValue);
          filtered.push({
            inputValue: params.inputValue,
            istituto: `${t("Aggiungi")} "${params.inputValue}"`,
            codice: '',
            titolo: '',
            comune: ''
          });
          
        }

        return filtered;
      }}
      id="free-solo-dialog-demo"
      options={schools.sort((a, b) => -b.codice.localeCompare(a.codice))}
      groupBy={(option) => option.provincia}
      getOptionLabel={(option) => {
        // e.g value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return `(${option["codice"].toUpperCase()}) ${option["tipo"]} ${option["istituto"]} - ${option["comune"]} `;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderOption={(props, option) => <li {...props}>{!option["codice"] ? `${option["istituto"]}` : `(${option["codice"]?.toUpperCase()}) ${option["tipo"]} ${option["istituto"]} - ${option["comune"]} `}</li>}
      sx={{ width: "100%" }}
      freeSolo
      renderInput={(params) => <TextField {...params} label={t("Inserisci qui il nome della tua scuola")} />}
    />
    <Modal isOpen={open}>
      <ModalHeader>
        {t("Inserimento nuovo Istituto")}
      </ModalHeader>
      <ModalBody>
        <Formik initialValues={{ "codice": "", "istituto": `${newSchoolName}`, "tipo": "", "comune": "" }}
          validationSchema={SchoolSchema}
          onSubmit={values => {
            //console.log("CALLED ONSUBMIT");
            setSelectedSchool({
              codice: values.codice,
              istituto: values.istituto,
              tipo: values.tipo,
              comune: values.comune
            });


            setValue({
              codice: values.codice,
              istituto: values.istituto,
              tipo: values.tipo,
              comune: values.comune
            });

            handleClose();
          }}

        >
          {({ errors, handleSubmit, touched, setFieldValue, handleChange, setFieldTouched, values, initialValues, validateForm }) => (

            <form id="schoolForm" onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>
              <FormGroup>
                <Label for="codice">
                  <b>{t("Codice meccanografico")}</b>
                </Label>
                <Input
                  id="codice"
                  name="codice"
                  label="codice"
                  type="text"
                  invalid={errors.codice != null}
                  tag={Field}
                />
                <FormFeedback>{errors.codice}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="istituto">
                  <b>{t("Nome dell'Istituto scolastico")}</b>
                </Label>
                <Input
                  id="istituto"
                  name="istituto"
                  label="istituto"
                  type="text"
                  invalid={errors.istituto}
                  tag={Field}
                />
                <FormFeedback>{errors.istituto}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="tipo">
                  <b>{t("Tipologia di Istituto")}</b>
                </Label>
                <Input
                  id="tipo"
                  name="tipo"
                  label="tipo"
                  type="text"
                  invalid={errors.tipo}
                  tag={Field}
                />
                <FormFeedback>{errors.tipo}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="comune">
                  <b>{t("Comune")}</b>
                </Label>
                <Input
                  id="comune"
                  name="comune"
                  label="comune"
                  type="text"
                  invalid={errors.comune}
                  tag={Field}
                />
                <FormFeedback>{errors.comune}</FormFeedback>
              </FormGroup>

            </form>)}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" type="submit" form="schoolForm">{t("Add school")}</Button>
        <Button onClick={handleClose}>{t("cancel")}</Button>

      </ModalFooter>
    </Modal>

  </div>


}

