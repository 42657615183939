import { createSlice, PayloadAction, createDraftSafeSelector, createSelector } from "@reduxjs/toolkit";
import { SYNCHRONOUS_SESSION_TYPE, INTERACTIVE_SESSION_TYPE } from "../sagas/remotesessions";
export const currentSlice = createSlice({
  name: 'ui_catalog',
  initialState: {
    isMenuOpen: localStorage.getItem("isMenuOpen")==null || localStorage.getItem("isMenuOpen")=="true" ? true : false,
    activitiesRunning: {} as any,
    isAppointmentEditorOpen : {[SYNCHRONOUS_SESSION_TYPE] : false, [INTERACTIVE_SESSION_TYPE] : false},
    isDirectorAppointmentEditorOpen : {[SYNCHRONOUS_SESSION_TYPE] : false, [INTERACTIVE_SESSION_TYPE] : false},
    initialData : null,
    isBackendErrorAlertModalOpen : [false as boolean,"" as string ]
  },
  reducers: {
    startActivityRunning: (state, action: PayloadAction<string>) => void (state.activitiesRunning[action.payload] = true),
    stopActivityRunning: (state, action: PayloadAction<string>) => void (delete state.activitiesRunning[action.payload]),
    
    openAppointmentEditor: (state: any, action: PayloadAction<any>) =>  
    void( state.initialData = action.payload, state.isAppointmentEditorOpen[
     (action.payload.type?.startsWith(INTERACTIVE_SESSION_TYPE) ? INTERACTIVE_SESSION_TYPE: SYNCHRONOUS_SESSION_TYPE)] = true as any),

    openDirectorAppointmentEditor: (state: any, action: PayloadAction<any>) =>  
    void( state.initialData = action.payload, state.isDirectorAppointmentEditorOpen[
      (action.payload.type?.startsWith(INTERACTIVE_SESSION_TYPE) ? INTERACTIVE_SESSION_TYPE: SYNCHRONOUS_SESSION_TYPE)] = true as any),
 
    closeAppointmentEditor:(state: any, action: PayloadAction<any>) =>  
    void( state.initialData = action.payload, state.isAppointmentEditorOpen[
      (action.payload.type?.startsWith(INTERACTIVE_SESSION_TYPE) ? INTERACTIVE_SESSION_TYPE: SYNCHRONOUS_SESSION_TYPE)] = false as any),
 
    closeDirectorAppointmentEditor:(state: any, action: PayloadAction<any>) =>  
    void( state.initialData = action.payload, state.isDirectorAppointmentEditorOpen[
      (action.payload.type?.startsWith(INTERACTIVE_SESSION_TYPE) ? INTERACTIVE_SESSION_TYPE: SYNCHRONOUS_SESSION_TYPE)] = false as any),
 
    openBackendErrorAlertModal: (state: any, action: PayloadAction<any>) =>  
    void( state.isBackendErrorAlertModalOpen = [true, action.payload]),
    closeBackendErrorAlertModal: (state: any, action: PayloadAction<any>) =>  
    void(state.isBackendErrorAlertModalOpen = [false, ""]),

    toggleMenuOpen:(state: any, action: PayloadAction<any>) =>  
    void(localStorage.setItem("isMenuOpen", `${!state.isMenuOpen}`) as any 
    || (state.isMenuOpen = !state.isMenuOpen as any))
  }
})

// Extract the action creators object and the reducer
export const { actions, reducer }: any = currentSlice
export const { startActivityRunning, stopActivityRunning } = actions

const activitiesRunningSelector = (state: any) => state.ui_catalog.activitiesRunning;
export const selectors = {
  activityRunningSelector: createDraftSafeSelector(
    (state: any) => state.ui_catalog.activitiesRunning,
    (_: any, currentActivity: string) => currentActivity,
    (activitiesRunning: any, currentActivity: any) => activitiesRunning[currentActivity] != undefined ? activitiesRunning[currentActivity] : false
  ),
  isAppointmentEditorOpen: (type:any) => (state: any) => state.ui_catalog.isAppointmentEditorOpen[
    (type==INTERACTIVE_SESSION_TYPE ? INTERACTIVE_SESSION_TYPE: SYNCHRONOUS_SESSION_TYPE)],
    
  isDirectorAppointmentEditorOpen: (type:any) => (state: any) => state.ui_catalog.isDirectorAppointmentEditorOpen[
    (type==INTERACTIVE_SESSION_TYPE ? INTERACTIVE_SESSION_TYPE: SYNCHRONOUS_SESSION_TYPE)],

  getInitialData: (state: any) => state.ui_catalog.initialData,
  getBackendErrorAlertModalInfo: (state:any) => state.ui_catalog.isBackendErrorAlertModalOpen,
  isMenuOpen:(state:any) => state.ui_catalog.isMenuOpen
}

