import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { Experiment } from './Experiment';
import ReactTooltip from "react-tooltip";
import { PathExperiment } from './PathExperiment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { Collapse } from "reactstrap"
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";

// Le icone delle tematiche sono state scaricate dal sito seguente:
// https://icons8.com/icons


export const Topic = (props: any) => {
  const { content } = props;

  const { t } = useTranslation('frontend', { useSuspense: false });
  const topicIcon = `/catalog/icons/${content.icon || "laboratory.png"}`
  const [isOpen, setOpen] = useState(props.allOpen);

  const getTopicLength = () => {
    let res = 0;
    for (let i = 0; i < content.esperimenti.length; i++) {
      if (content.esperimenti[i]["type"] != "path") { res = res + 1 }
      else {
        res = res + content.esperimenti[i]["esperimenti"].length
      }
    }
    return res;
  }

  const amountOfExperiments = getTopicLength();

  const renderExperiments = (id: any) => {
    const experiments = content["esperimenti"];
    if (!experiments || experiments.length === 0) {
      return null; // Gestione caso array vuoto o non definito
    }
    //console.log("ID:", id);
    return content["esperimenti"].map((experiment: any, index: number) => {
      return (
        experiment["type"] == "path" ?
          <PathExperiment id={`PEID_${id}_${experiment.id}_${index}`}
            key={`PE_${id}_${experiment.id}_${index}`} content={experiment} /> :
          <Experiment key={`EXP_${id}_${experiment.id}_${index}`} content={experiment} />

      )

    })
  }

  useEffect(() => {
    setOpen(props.allOpen)
  }, [props.allOpen])

  return (
    <Card key={`CARD_ ${props.id}`} className="mb-4" style={{
      borderColor: "#007bff"
    }}>
      <CardHeader data-tip={t("Area tematica")} data-for="tooltip_topic"
        onClick={(ev) => { setOpen(!isOpen) }}
        style={{
          display: "flex", justifyContent: "space-between",
          backgroundColor: "#007bff",
          borderColor: "#007bff",
          paddingBottom: 0,
          color: 'white', cursor: "pointer"
        }}>
        <div
          style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
          <img src={topicIcon} style={{ marginRight: "8px", marginBottom: "8px" }}
            width="48" height="48"
            alt='not found' />
          <CardTitle tag="h5">{`${content.titolo} (${amountOfExperiments} ${amountOfExperiments > 1 ? t("esperimenti") : t("esperimento")})`}</CardTitle>
        </div>
        {isOpen ?
          <AiOutlineCaretUp style={{ marginTop: "8px" }} size={"1.6em"} cursor="pointer" color='white' onClick={() => { setOpen(false) }}></AiOutlineCaretUp> :
          <AiOutlineCaretDown style={{ marginTop: "8px" }} size={"1.6em"} cursor="pointer" color='white' onClick={() => { setOpen(true) }}></AiOutlineCaretDown>
        }
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody key={`CB_${props.id}`} >
          {renderExperiments(`${props.id}`)}
        </CardBody>
      </Collapse>
      <ReactTooltip id="tooltip_topic" />
    </Card>)
}