import { createSlice, PayloadAction } from "@reduxjs/toolkit";
 

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
      users : {},
      registeredUsers: [],
      userProfiles : {},
      currentUserData : null,
      lastUserProfile : null,
      myStudents : []
    }
  ,
    reducers: {
        willGetUser: (state, action: PayloadAction<any>) => state,
        willGetRegisteredUsers: (state, action: PayloadAction<any>) => state,
        willGetUserProfileAsDirector: (state, action: PayloadAction<any>) => state,
        willGetStudentsByTeacherCode: (state, action: PayloadAction<any>) => state,
        setCurrentUserData: (state, action: PayloadAction<any>) => { 
          state.currentUserData = action.payload;
        },

        didGetUser: (state, action: PayloadAction<any>) =>
         { 
           //console.log("DDDD Richiesta aggiornamento user con id:",action.payload.id);
          (state.users as any)[action.payload.id] = action.payload;},
        
          didGetMyStudents: (state, action: PayloadAction<any>) =>
         { state.myStudents  = action.payload as any;},

        didGetRegisteredUsers: (state, action: PayloadAction<any>) =>
          { 
           (state.registeredUsers as any)  = action.payload;
          },
        
        didGetUserProfileAsDirector: (state, action: PayloadAction<any>) =>
         { (state.userProfiles as any)[action.payload.user] = action.payload;
            state.lastUserProfile = action.payload;
        }
    }
});

export const isSardinianTeacher = (profile:any) =>
{
  // per retrocompatibilità, non si usa il campo school["type"] per verificare
  // se un utente è di tipo "teacher", ma si controlla se il campo school
  // è un valore diverso dalla sequenza "--" che viene assegnato a utenti NON docenti.
  if (!profile || ! profile["school"] || profile["school"]=="--") return false;
  const prov = profile["school"].replace(new RegExp('"', "g"), "").slice(0,3)
  return (
    prov == "(CA" ||
    prov == "(OR" ||
    prov ==  "(NU" ||
    prov ==  "(SS" 
  )
}


export const { actions, reducer }: any = usersSlice

export const selectors = {
  getUser: (id:any) => (state: any) => {
    const userdata = state.users.users[id];
    return userdata;
  },
  getUsers: (state:any) =>{
    return state.users
  },

  getUsers2: (state:any) =>{
    return state.users.users
  },

  getRegisteredUsers: (state:any) =>{
    return state.users.registeredUsers
  },


  getUserProfiles: (state:any) =>{
    return state.users.userProfiles
  },

  getUserProfileAsDirector: (id:any) => (state: any) => {
    const userProfileData = state.users.userProfiles[id];
    return userProfileData;
  },
  getCurrentUserData : (state:any) => {
     return state.users.currentUserData;
  },
  getLastUserProfile : (state:any) => {
    return state.users.lastUserProfile;
 },
 getMyStudents : (state:any) => {
  return state.users.myStudents;
}
}