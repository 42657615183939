
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom";
import { useSelector } from "react-redux";
import { history } from './store'
import { ConnectedRouter } from 'connected-react-router'
import { LoginPage } from './pages/login'
import { IFrameCatalogPage } from "./pages/IFrameCatalog";
import { SignupPage } from './pages/signup'
import { SignupConfirmPage } from './pages/signupConfirm'
import { HomePage } from "./pages/home";
import { DashboardPage } from "./pages/dashboard";
import {DirectionDashboardPage} from "./pages/directionDashboard";
import { DetailExperiment } from './pages/experimentDetails'
import { TimelineDetail } from './pages/timelineDetails'
import { TimelineHome } from './pages/timelineHome'
import { PublicExperiment } from './pages/PublicExperiment'
import {DirectorSyncSession} from './pages/DirectorLiveExperiment'
import {RegistrationForm} from './pages/RegistrationForm'
import {DataAnalyzerPage}  from './pages/dataAnalytics'
import { InteractiveSessionPage } from "./pages/InteractiveSessionHome";
import { DirectorInteractiveSessionPage} from "./pages/DirectorInteractiveSessionHome";
import { selectors as AuthSelectors } from './store/slices/auth'
import { selectors as ProfileSelectors } from './store/slices/profile'
import { CatalogPage , CatalogMap } from "./pages/catalog";
import { CalendarPage } from "./pages/calendar";
import { MapsHomepage } from "./pages/MapsHomepage";
import { LatestPublicExperimentRedirect } from "./components/LatestPublicExperimentRedirect";


const PrivateRoute = ({ children, ...rest }: any) => {

  const isLogged = useSelector(AuthSelectors.isLogged)
  const isChecked = useSelector(AuthSelectors.isChecked)

  //@audit-info commentatare il seguente useSelector e mettere false per debug
  const isRegistered = useSelector(AuthSelectors.isRegistered)

  //console.log(`redux debug router isChecked:${isChecked} isLogged:${isLogged} isRegistered:${isRegistered}`);
  return (
    <Route
      {...rest}
      render={
        props => {
          //console.log("isLogged: ", isLogged)
          return (
            <>
            {isChecked ? (
                isLogged ?  
                  (isRegistered ? (      
                                    children
                                  )              
                      : (<Redirect to="/registration_form" />) 
                    )
                       : (
                            <Redirect to="/login" />
                          )
              
                      ) : (
                        <p>waiting</p>
                      )
                    }   

          </>
          )
        }
      }
    />
  )
}

export const AppRouter = () => {

  const isLogged = useSelector(AuthSelectors.isLogged)
 
  return (
    <ConnectedRouter history={history}>
      {/* <Header className="mb-3 text-white" />
      <Container fluid> */}
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/iframe_catalog">
          <IFrameCatalogPage />
        </Route>
        <Route path="/signup/confirm/:code">
          <SignupConfirmPage />
        </Route>
        <Route path="/signup/confirm/">
          <SignupConfirmPage />
        </Route>
        <Route path="/signup">
          <SignupPage />
        </Route>
        <Route path="/experiment/:experiment">
          <DetailExperiment />
        </Route>
        <PrivateRoute path="/edit_profile">
          <RegistrationForm edit/>
       </PrivateRoute> 
       <PrivateRoute path="/direction">
          <DirectionDashboardPage/>
       </PrivateRoute> 
       <PrivateRoute path="/data_analytics">
          <DataAnalyzerPage/>
       </PrivateRoute> 
        {/* 
       <PrivateRoute path="/redirect_url">
          <WenetConnector/>
       </PrivateRoute> 
       */}
        <Route path="/registration_form">
          <RegistrationForm/>
        </Route>
        <PrivateRoute path="/dashboard">
          <DashboardPage />
        </PrivateRoute>
        <PrivateRoute path="/catalog">
          <CatalogPage />
        </PrivateRoute>
        <PrivateRoute path="/map">
          <MapsHomepage />
        </PrivateRoute>
        <PrivateRoute path="/calendar">
          <CalendarPage />
        </PrivateRoute>
        <PrivateRoute path="/live/:organization/:id/:experiment_id">
          <DirectorSyncSession />
        </PrivateRoute>
        <PrivateRoute path="/director_live/:organization/:id/:experiment_id">
          <DirectorSyncSession/>
        </PrivateRoute>
        <PrivateRoute path="/idevices/:experiment_id/:interactive_device_id">
            <DirectorInteractiveSessionPage/>
        </PrivateRoute>
        <PrivateRoute path="/isession/:organization/:interactive_session_id/:experiment_id/:device_id">
          <InteractiveSessionPage/>
       </PrivateRoute> 
        <Route path="/public/:id/latest">
            <LatestPublicExperimentRedirect/>
        </Route>
        <Route path="/public/:id">
            <PublicExperiment/>
        </Route>
        <PrivateRoute path="/timeline/:id">
          <TimelineDetail />
        </PrivateRoute>
       
        <PrivateRoute path="/timeline">
          <TimelineHome />
        </PrivateRoute>
       
        <Route path="/">
          {isLogged ? (
            <CatalogPage />
          ) : (
            <HomePage />
          )}
        </Route>
      </Switch>
      {/* </Container> */}
    </ConnectedRouter>
  )
}
