//https://github.com/securedeveloper/react-data-export/blob/master/examples/styled_excel_sheet.md

import ReactExport from "react-data-export";
import { useDispatch, useSelector } from 'react-redux';
import { selectors as UsersSelectors, actions as UsersActions } from '../store/slices/users'
import {useState, useEffect}  from 'react';
import moment from 'moment';

import {FaFileExcel} from "react-icons/fa";
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from "react-tooltip";
import {getClasseConcorsoByCode} from "../pages/constants"
import { useTranslation } from 'react-i18next';
import { INTERACTIVE_SESSION_TYPE } from "../store/sagas/remotesessions";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const multiDataSet = [
  {
      columns: [
          {title: "Headings", width: {wpx: 80}},//pixels width 
          {title: "Text Style", width: {wch: 40}},//char width 
          {title: "Colors", width: {wpx: 90}},
      ],
      data: [
          [
              {value: "H1", style: {font: {sz: "24", bold: true}}},
              {value: "Bold", style: {font: {bold: true}}},
              {value: "Red", style: {fill: {patternType: "solid", fgColor: {rgb: "FFFF0000"}}}},
          ],
          [
              {value: "H2", style: {font: {sz: "18", bold: true}}},
              {value: "underline", style: {font: {underline: true}}},
              {value: "Blue", style: {fill: {patternType: "solid", fgColor: {rgb: "FF0000FF"}}}},
          ],
          [
              {value: "H3", style: {font: {sz: "14", bold: true}}},
              {value: "italic", style: {font: {italic: true}}},
              {value: "Green", style: {fill: {patternType: "solid", fgColor: {rgb: "FF00FF00"}}}},
          ],
          [
              {value: "H4", style: {font: {sz: "12", bold: true}}},
              {value: "strike", style: {font: {strike: true}}},
              {value: "Orange", style: {fill: {patternType: "solid", fgColor: {rgb: "FFF86B00"}}}},
          ],
          [
              {value: "H5", style: {font: {sz: "10.5", bold: true}}},
              {value: "outline", style: {font: {outline: true}}},
              {value: "Yellow", style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFFF00"}}}},
          ],
          [
              {value: "H6", style: {font: {sz: "7.5", bold: true}}},
              {value: "shadow", style: {font: {shadow: true}}},
              {value: "Light Blue", style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}}
          ]
      ]
  }
];

const ReportDownloader = (props) => {

   const [appointmentsDataArray, setAppointmentsDataArray] = useState([])

    const appointments =  props.appointments;  //useSelector(AppointmentsSelector.getAppointments);
    const experiments = props.experiments; //useSelector(CatalogSelector.getExperiments);
    const sessionDirectors = props.sessionDirectors; // useSelector(AppointmentsSelector.getAllSessionDirectors);
    const directors = props.directors;
    const users = useSelector(UsersSelectors.getUsers)
    const { t, i18n } = useTranslation('frontend', { useSuspense: false });
    //console.log("Session Directors:::", sessionDirectors);


    const getDirectorNameById = (id) => {
      for(let i=0;i<directors.length;i++)
      {
        if (directors[i]["director"]==id)
        return directors[i]["name"]
      }
      return null;
    }

    const getSessionDirectorNames = (sessionDirectors) => {
      let msg = ""
      for (let i=0;i<sessionDirectors.length;i++)
      {
        if (i>0) msg+=", "
        const name = getDirectorNameById(sessionDirectors[i]["director"]);
        if (name!=null)
        msg += name;
      }
      return msg;
    }
    const dispatch = useDispatch();


    const getLivelloScolastico = (k) => {

      const livelli = {"0" : "Scuola primaria" , "1" : "Scuola secondaria di I grado", "2" : "Scuola secondaria di II grado"}
      return livelli[k] || "N.A";
    }

    const getGradoScolastico = (k) => {

      const gradi = {"1" : "Classe I" , "2" : "Classe II", "3" : "Classe III", "4" : "Classe IV", "5": "Classe V"}
      return gradi[k] || "N.A";
    }

    const getFileName = () => {
      
      const now_date = moment(moment.now()).format("DD_MM_YY")
      const now_time = moment(moment.now()).format("HH:mm")
      const prefix = (props.type==INTERACTIVE_SESSION_TYPE) ? "interactive_" : ""
      return `riale_${prefix}sessions_${now_date}_h_${now_time}`;
    }
      
    useEffect(() =>{

      const usersSet = new Set();
      appointments.forEach((appointment) => {
       
        if (appointment.attendee!="CODE_UNBOOKED")
        {
          usersSet.add(appointment.attendee)
        }
      });

      usersSet.forEach((user) =>{
      //console.log("DDDD:Provo a caricare i dati dell'utente -> ",user);
      dispatch(UsersActions.willGetUser(user));
      dispatch(UsersActions.willGetUserProfileAsDirector(user));
      })
    }, [])

    useEffect(() =>{
      //console.log("TTTT:Aggiornato users:", users);
    }, [users])

    useEffect(() =>{
      setAppointmentsDataArray(
        //[[{"value": "prova1"},{"value" : "prova2"}, {"value": "prova3"}]]
        getAppointmentsArrayData(appointments)
        )
    }, [experiments,appointments, users]); // users serve per caricare i dati sul profilo utente!

    const appointmentsDataSet = [
      {
        columns: [
          {title: "Data",  width: {wpx: 100}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},//pixels width 
          {title: "Stato", width: {wpx: 90}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
          {title: "Esperimento", width: {wpx: 150}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
          {title: "Laboratorio", width: {wpx: 150}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},//char width 
          {title: "Docente", width: {wpx: 120}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
          {title: "Email", width: {wpx: 100}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
          {title: "Istituto", width: {wpx: 150}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
          {title: "Tipologia di Istituto", width: {wpx: 150}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
          {title: "Materia insegnata", width: {wpx: 150}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
          {title: "Livello scolastico", width: {wpx: 120}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
          {title: "Grado scolastico", width: {wpx: 120}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
          {title: "Regia", width: {wpx: 120}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
          
        ],
      data: appointmentsDataArray
      }
    ]

    const getAppointmentsArrayData = (appointments) =>{
      const data = []
      appointments.forEach((appointment) => {
        //console.log("Utenti -> ",users);
        const eventId = appointment["title"];
        const experiment = experiments ? experiments[eventId] : null;
        const attendeeAttributes =  (users) ? users["users"][appointment["attendee"]] : null;
        const attendeeFullName =  attendeeAttributes== null ? "" : `${attendeeAttributes.given_name} ${attendeeAttributes.family_name}`
        
        const attendeeProfile = (users) ? users["userProfiles"][appointment["attendee"]] : null;
       
        const materiaInsegnataInfo = getClasseConcorsoByCode(attendeeProfile && attendeeProfile["schoolMatter"])  
        const materiaInsegnata = materiaInsegnataInfo ? `${materiaInsegnataInfo["code"]} - ${materiaInsegnataInfo["description"]}` : "";

        //console.log(`Session Directors in getAppointmentsArrayData ${appointment["id"]}:`,sessionDirectors);
        data.push([
          
          {value: moment( appointment["startDate"]).format("DD/MM/YY HH:mm") , style:{alignment:{vertical: "center"}} }
             ,  
        
                   appointment["state"]=="booked" ? 
                   {value :"Prenotato" , style:{  alignment:{vertical: "center"},
                                           fill: {patternType: "solid", fgColor: {rgb: "FF00FF00"}}}} : 
                   {value :"Non prenotato" , style:{alignment:{vertical: "center"}, font:{color:{rgb: "FFFFFFFF"}}, 
                   fill: {patternType: "solid", fgColor: {rgb: "FFFF0000"}}}} ,

                   {value : (experiment ? experiment["titolo"] : "") , 
                   style : {font:{vertAlign:true}, alignment: {wrapText : true, vertical:"center"} } },

                   {value: appointment["lab"], style:{alignment: {wrapText : true, vertical:"center"}}},
                   
                   {"value" :attendeeFullName , style:{alignment:{vertical: "center"}}},
                   {value: (attendeeAttributes!=null) ? attendeeAttributes.email : "", style:{alignment:{vertical:"center"}}},

                   { value: attendeeProfile ? attendeeProfile["school"] : "" , style:{alignment: {wrapText : true, vertical:"center"}}},
                   { value: attendeeProfile ? attendeeProfile["schoolType"] : "" , style:{alignment: {wrapText : true, vertical:"center"}}},
                   { value: materiaInsegnata , style:{alignment: {wrapText : true, vertical:"center"}}},
                   
                   { value: appointment["state"]=="booked" ? getLivelloScolastico(appointment["schoolLevel"]) : "" , 
                     style:{alignment: {wrapText : true, vertical:"center"}}},
                   
                  { value: appointment["state"]=="booked" ? getGradoScolastico(appointment["schoolGrade"]) : "" , 
                     style:{alignment: {wrapText : true, vertical:"center"}}},
                   
                   { value : sessionDirectors && sessionDirectors[appointment["id"]] ? 
                   getSessionDirectorNames(sessionDirectors[appointment["id"]]) : "" , 
                   style:{alignment: {wrapText : true, vertical:"center"}}},

                 
                  ])
      })

      return data;
    }

        return (
          <>
            <ExcelFile 
            filename= {getFileName()}
            element = {
              <IconButton 
              style={{float: 'right', marginTop:'-10px',  marginRight:'-10px'}}
               >
                  <IconContext.Provider
                  value={{ color: `white`,size: "1em"}}>
                     <FaFileExcel data-place="top"  
                     data-for="excelExportTooltip" data-tip={t("Esporta in formato Excel")}
                     />
                  </IconContext.Provider>
              </IconButton>
            }

          
            
            > 
                <ExcelSheet dataSet={appointmentsDataSet} name={props.type==INTERACTIVE_SESSION_TYPE ? "sessioni pratiche": "sessioni sincrone"}></ExcelSheet>
              
            </ExcelFile>
             <ReactTooltip id="excelExportTooltip"/> 
             </>
        );
}

export default ReportDownloader;