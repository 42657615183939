import { NavItem, NavLink, Nav } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectors as UiSelector, actions as UIAction } from '../store/slices/ui'
import  { useState, useEffect } from 'react';
import { SideBar } from '../components/SideBar'
import { useSelector, useDispatch } from "react-redux";
import { selectors as AuthSelectors } from '../store/slices/auth'
export const Content = ({ children, className, active }: any) => {
  const dispatch = useDispatch();
  const isLogged = useSelector(AuthSelectors.isLogged)
  const isMenuOpen = useSelector(UiSelector.isMenuOpen);

  const toggleOpen = () => { dispatch(UIAction.toggleMenuOpen())}
  return (
    <>
    {
      isLogged && (
        <SideBar active={active} isOpen={isMenuOpen} onToggleOpen={() => toggleOpen()} />
      )
    }
    
    <div className={className} style={{
      border: '0px solid #000',
      // width: '240px',
      position: 'absolute',
      backgroundColor: 'white',
      bottom: 0,
      top: 56,
      left: (!isLogged ? 0 : (!isMenuOpen ? 48 : 240)),
      right: 0
    }}>
       
      {children}
    </div>
    </>
  )
}