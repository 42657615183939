import { useState, useEffect } from 'react'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/it'
import { useDispatch, useSelector } from 'react-redux';
import {
  selectors as AppointmentsSelector, actions as AppointmentActions, getAppointmentById,
  Appointmentstate, isNoLongerAvailable
} from '../store/slices/appointments'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { selectors as AuthSelectors } from '../store/slices/auth'
import { actions as UiActions } from '../store/slices/ui';
import { actions as CatalogActions, selectors as CatalogSelectors } from '../store/slices/catalog'
import { Container, Row, Label, Badge } from 'reactstrap';
import { isSardinianTeacher } from '../store/slices/users'
import Flag from 'react-world-flags'
import { gradi } from "../pages/constants"
import { useTranslation } from 'react-i18next';
import { BUSY_COLOR, BOOKED_COLOR, UNBOOKED_COLOR, RESERVED_COLOR, NO_LONGER_AVAILABLE_COLOR } from '../consts/colors'
import { SYNCHRONOUS_SESSION_TYPE, INTERACTIVE_SESSION_TYPE } from '../store/sagas/remotesessions';
import { IconContext } from "react-icons";
import { FaBriefcase, FaMicroscope } from 'react-icons/fa';
import { LuMicroscope } from 'react-icons/lu';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GiMicroscope } from 'react-icons/gi';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBriefcase
} from "@fortawesome/free-solid-svg-icons";
import interactiveSessionFlag from '../assets/telescope.svg'
const localizer = momentLocalizer(moment);


const AppointmentsViewer = (props: any) => {

  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const userAttributes = useSelector(ProfileSelectors.getProfile)
  const currentRegistrationProfile = useSelector(AuthSelectors.getRegistrationProfile)
  const [appointments, setAppointments] = useState([])

  useEffect(() => {
    //console.log("Sono nel costruttore: Carico TUTTI gli esperimenti dal catalogo");
    dispatch(CatalogActions.willLoadExperiments());
  }, [])

  useEffect(() => {
    //console.log("Carico gli esperimenti dal catalogo");
    const { experimentId } = props;

    dispatch(CatalogActions.willLoadExperiments());

    // a seconda che stia visionando il calendario completo o quello relativo
    // ad un esperimento specifico carico tutte le sessioni o solo quelle 
    // dell'esperimento di interesse! -> fatto come filter....
    /*
    if (experimentId!=null)
    {
      dispatch( AppointmentActions.willLoadAppointmentsByTitle(experimentId));
    }                                               
    else
    */
    {
      //console.log("Esperimento non specificato..carico tutti gli appuntamenti type:", props.type);
      dispatch(AppointmentActions.willLoadAppointments(
        {
          "start": "1900-01-20T10:00:00.000Z", "end": "2050-01-20T10:00:00.000Z",
          "type": props.type || ""
        }
      ));
    }


  }, [props.experimentId])

  const appointmentsSel = useSelector(AppointmentsSelector.getAppointmentsOrInteractiveSessions(props.type))

  const experiments = useSelector(CatalogSelectors.getExperiments);

  const dispatch = useDispatch();

  const canEdit = userAttributes != null && userAttributes.groups != null &&
    userAttributes.groups.includes("editor");
  
  useEffect(() => {
    if (appointmentsSel != null) {
      if (props.experimentId == null) {
        setAppointments(appointmentsSel);
      }
      else
        setAppointments(appointmentsSel.filter((app: any) => { return app["title"] == props.experimentId }))
    }

  }, [appointmentsSel, props.experimentId])

  const addAppointment = (event: any) => {
    event.state = 0;
    const newAppointment = {
      "startDate": event.start, "stopDate": event.end,
      "type": props.type,
    }
    //console.log("(addAppointment) Passo il payload :", newAppointment);
    //console.log("Valore di UiActions:", UiActions)
    dispatch(UiActions.openAppointmentEditor(newAppointment));
  }

  const editAppointment = (event: any) => {
    const appointment = getAppointmentById(appointments, event.id);
    //console.log("(editAppointment) Passo il payload :", appointment);
    // se non è prenotato dall'utente loggato e se è riservato ad altri, non consento l'accesso
    if (!(appointment.attendee == userAttributes.sub) && isReserved(appointment) && !canEdit) {
      //console.log("Appuntamento riservato");
      return;
    }
    if (!canEdit && isNoLongerAvailable(appointment)) {
      //console.log("Appuntamento non più disponibile...Accesso non autorizzato");
      return;
    }
    dispatch(UiActions.openAppointmentEditor(appointment));
  }

  useEffect(() => {
    //console.log("valore corrente di experiments:", experiments);
  }, [experiments]);

  const getInteractiveDeviceById = (initialData:any, ideviceId: any) => {
     
    const currentExperiment = experiments[initialData?.title]
    //console.log(`AE: InitialData: on deviceId:${ideviceId}`, initialData, currentExperiment);
    if (currentExperiment==null) return {"titolo" : "<Not found>", "descrizione" : "N.A (2)"}
    const result =  currentExperiment["interactive_devices"]?.filter((device:any) => {
     return device["id"]==ideviceId
    })
    if (result?.length>0) return (result[0])
    else return {"titolo" : "<Not found 2>", "descrizione" : "N.A (2)"}
   }
   
const getInteractiveDeviceId = (initialData: any) => {
  if (initialData?.type == null || !initialData.type.startsWith(INTERACTIVE_SESSION_TYPE)) return null;
  const isessionId = initialData.type.split(`${INTERACTIVE_SESSION_TYPE}__`)[1]
  //console.log("ISESSION ID:", isessionId)
  return isessionId
}

  const renderGradeOptions = () => {
    //console.log("gradi scolastici:", gradi);
    return gradi.map(
      (e: any, i: number) => <option key={e["code"]} value={e["code"]}>{e["description"]}</option>);
  }

  const renderTitle = (ev: any) => {
    //if (ev["language"] != null) console.log("Calendar Event:", ev);

    const eventId = ev["title"];
    const experiment = experiments && experiments[eventId];

    //console.log(`richiamato render title con experiment:${eventId}`, experiment);
    return (
      props.type == INTERACTIVE_SESSION_TYPE ?
        (
          <span style={{ display:"flex", justifyContent:"flex-start" }}>   
            <img src={interactiveSessionFlag} height="16px"
          style={{
            "border": "1px solid white", "marginTop": "4px", "marginBottom": "2px",
            "marginLeft": "4px", "marginRight": "4px"
          }} />
            {/*(experiment && experiment["titolo"]) || ev["title"]*/}
            {getInteractiveDeviceById(getAppointmentById(appointments, ev.id),getInteractiveDeviceId(getAppointmentById(appointments, ev.id)))["titolo"]}
            </span>)
        : 
        (<div style={{ display: "flex" }}><Flag code={(ev["language"]) || "IT"} height="14"
          style={{
            "border": "1px solid white", "marginTop": "5px", "marginLeft": "4px",
            "marginRight": "4px"
          }} />
          {(experiment && experiment["titolo"]) || ev["title"]}</div>) as any
    )


  }

  const renderTooltip = (ev: any) => {
    const eventId = ev["title"];
    const experiment = experiments && experiments[eventId];
    return (
      (experiment && experiment["titolo"]) || ev["title"]) as any
  }



  const isReserved = (appointment: any) => {
    if (appointment == null) return true;

    // se l'appuntamento è riservato ai docenti sardi e l'utente
    // correntemente loggato non lo è, l'appuntamento non è accessibile per quell'utente
    if (appointment.reservedToSardinianTeachers == true) {
      const sardinianTeacher = isSardinianTeacher(currentRegistrationProfile)
      //console.log("Sardinian Teacher?:", currentRegistrationProfile, sardinianTeacher);
      if (!sardinianTeacher) return true;

    }
    // se sono qui ci sono 2 possibilità:
    // 1) sessione NON riservata ai sardi (dovro' accertarmi se è riservata a specifici utenti)
    // 2) sessione riservata ai sardi con utente sardo loggato (dovro' accertarmi se è riservata a specifici utenti sardi)

    //console.log("BF1:Sessione riservata a:", appointment.reservedTo);
    //console.log("BF1:UserAttributes sub:", userAttributes.sub);
    if (appointment.reservedTo == null) return false;
    // l'appuntamento è riservato se l'id dell'utente loggato non è incluso nella
    // lista degli utenti con permesso di accesso, sempre che tale lista esista e non sia nulla

    // N.B: Se dovessero apparire nella lista dei nomi di docenti non sardi ma
    // l'appuntamento è riservato ai sardi, l'utente NON POTRA' comunque accedere
    // all'appuntamento! ( a questo punto della funzione non si arriverebbe...)
    return !(appointment.reservedTo.map((e: any) => e["id"]).includes(userAttributes.sub))
  }

  const handleEvent = (
    event: any,
    start: any,
    end: any,
    isSelected: any
  ) => {

    const currentAppointment = getAppointmentById(appointments, event.id);
    //console.log("Appuntamento corrente:", currentAppointment);
    const eventColor = (currentAppointment != null &&
      currentAppointment.state == Appointmentstate.BOOKED) ?
      (currentAppointment.attendee == userAttributes.sub ? BOOKED_COLOR : BUSY_COLOR) :
      (isNoLongerAvailable(currentAppointment) ? NO_LONGER_AVAILABLE_COLOR :
        (isReserved(currentAppointment) ? RESERVED_COLOR : UNBOOKED_COLOR));
    ////console.log(`Event color:${eventColor} state:${currentAppointment.state}`);
    return { style: { color: "white", backgroundColor: eventColor } }
  }
  ////console.log("Appointments da render", appointments);
  return (
    <Container fluid>

      <Calendar
        culture={i18n.language}
        selectable={canEdit}
        popup={true}
        localizer={localizer}
        events={appointments}
        startAccessor="startDate"
        endAccessor="stopDate"
        titleAccessor={(ev) => renderTitle(ev)}
        tooltipAccessor={(ev) => renderTooltip(ev)}
        onSelectEvent={(event) => editAppointment(event)}
        onSelectSlot={(event) => addAppointment(event)}
        style={{ height: 500 }}
        eventPropGetter={handleEvent}
        messages={{
          month: t("Month"), week: t("Week"), day: t("Day"), agenda: t("Agenda"), previous: t("Back"),
          today: t("Today"), next: t("Next"), showMore: (count: any) => `${t("Show more", { count })}`
        }}
      //views={{ month: true, week: MyWeek }}
      />
      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: BUSY_COLOR }}>{t("Occupato")}</Badge>
        <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: BOOKED_COLOR }}>{t("Prenotato da me")}</Badge>
        <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: UNBOOKED_COLOR }}>{t("Disponibile")}</Badge>
        <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: RESERVED_COLOR }}>{t("Riservato")}</Badge>

        <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: NO_LONGER_AVAILABLE_COLOR }}>{t("Non disponibile")}</Badge>

      </Row>
    </Container>
  )
}

export default AppointmentsViewer;