//https://formidable.com/open-source/victory/docs/victory-chart/

import { createContainer, VictoryPie, VictoryChart, VictoryBar, VictoryTooltip, VictoryAxis, VictoryStack, VictoryZoomContainer, VictoryBrushContainer, VictoryLabel, VictoryLegend, Border } from "victory";
import { useTranslation } from 'react-i18next';
import {
    Label, Input, Alert, Card, CardText, CardBody, 
    CardHeader, CardFooter, CardTitle,  Form, FormGroup
} from 'reactstrap';
import DateTime from 'react-datetime';
import moment from 'moment'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as AppointmentsSelector, actions as AppointmentActions, Appointmentstate, getAppointmentById } from '../store/slices/appointments'
import { selectors as CatalogSelector } from '../store/slices/catalog'
import { selectors as UsersSelectors, actions as UsersActions } from '../store/slices/users'
import ReactTooltip from "react-tooltip";

const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";
const DATE_FORMAT = "DD/MM/YYYY";
const TIME_FORMAT = "HH:mm";

const colors = ["#FF7105", "#236AB9", "#75ACF0", "#B85B14", "#341C09", "#126399", "#00AA00", "#AA0000", "#231688", "#A10344"]

export const DataAnalyzer = (props) => {


    const appointments = useSelector(AppointmentsSelector.getAppointments);
    const experiments = useSelector(CatalogSelector.getLiveExperiments);
    const users = useSelector(UsersSelectors.getUsers)
    const [amountOfAppointments, setAmountOfAppointments] = useState([])
    const [amountOfTeachers, setAmountOfTeachers] = useState({})
    const [amountOfSchools, setAmountOfSchools] = useState({})
    const [pieData, setPieData] = useState([])
    const [colorsMap, setColorsMap] = useState([])
    const [experimentTrendData, setExperimentTrendData] = useState([])
    const [experimentsSummaryData, setExperimentsSummaryData] = useState([])
    const [currentStartDateFilter, setCurrentStartDateFilter] = useState(null)
    const [currentStopDateFilter, setCurrentStopDateFilter] = useState(null)
    const [currentStateFilter, setCurrentStateFilter] = useState("0")
    const [selectedExperiment, setSelectedExperiment] = useState(null)
    const [graphType, setGraphType] = useState("Pie")
    const [zoomDomain, setZoomDomain] = useState(null); //{ x: [new Date(2021, 1, 1), new Date(2023, 5, 1)] })
    const [selectedDomain, setSelectedDomain] = useState(null) //{ x: [new Date(2021, 1, 1), new Date(2023, 5, 1)] })

    const [zoomSummaryDomain, setZoomSummaryDomain] = useState(null);
    const [selectedSummaryDomain, setSelectedSummaryDomain] = useState(null)

    const { t } = useTranslation('frontend', { useSuspense: false });
    const dispatch = useDispatch();

    // see https://formidable.com/open-source/victory/docs/create-container/
    const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");

    useEffect(() => {
        if (appointments && experiments) {
            const amountOfAppointments = getAppointmentsDistribution(currentStartDateFilter,
                currentStopDateFilter, currentStateFilter).filter((e) => e["count"] > 0)
            let pieData = amountOfAppointments.map((experiment) => {
                return {
                    "x": experiment["count"], "id": experiment["id"],
                    "y": experiment["count"]
                }
            })

            pieData.sort((a, b) => b["y"] - a["y"]);
            const colorsArr = pieData.map((data, index) => { return { "id": data["id"], "color": colors[index % colors.length] } })
            const newColorsMap = colorsArr.reduce((a, v) => { return { ...a, [v["id"]]: v["color"] } }, {})
           
            setColorsMap(newColorsMap);
            setAmountOfAppointments(amountOfAppointments)
            setPieData(pieData)
            amountOfAppointments.length > 0 ?
                setSelectedExperiment(experiments[Object.keys(experiments)[0]]) : setSelectedExperiment(null);

        }

    }, [appointments, experiments, currentStartDateFilter, currentStopDateFilter, currentStateFilter, users])


    useEffect(() => {
        if (selectedExperiment != null) {
            const newData = getAppointmentsHistoryDistribution(selectedExperiment["id"], currentStartDateFilter,
                currentStopDateFilter, currentStateFilter)
            setExperimentTrendData(newData);
        }

    }, [selectedExperiment,appointments, experiments, currentStartDateFilter, currentStopDateFilter, currentStateFilter, users])


    useEffect(() => {
        if (experimentTrendData != null) {
            if (experimentTrendData.length > 0) {
                const domain = {
                    x: [moment(experimentTrendData[0].x).add(-30, "days").toDate(),
                    moment(experimentTrendData.slice(-1)[0].x).add(30, "days").toDate()]
                }
                setZoomDomain(domain)
                setSelectedDomain(domain)
            }

        }

    }, [experimentTrendData])

    useEffect(() => {
        if (experimentsSummaryData != null && appointments != null && appointments.length > 0) {

            const startDateFilter = currentStartDateFilter || moment(appointments[0]["startDate"]);
            const stopDateFilter = currentStopDateFilter || moment(appointments[appointments.length - 1]["startDate"]);

            const domain = {
                x: [moment(startDateFilter).add(-30, "days").toDate(),
                moment(stopDateFilter).add(30, "days").toDate()]
            }


            /*
            if (experimentsSummaryData.length > 0 && experimentsSummaryData[0].length>0 ) {
                const domain = {
                    x: [moment(experimentsSummaryData[0][0].x).add(-15, "days").toDate(),
                    moment(experimentsSummaryData[0].slice(-1)[0].x).add(15, "days").toDate()]
                }
            */

            setZoomSummaryDomain(domain)
            setSelectedSummaryDomain(domain)
        }


    }, [experimentsSummaryData])


    useEffect(() => {
        if (appointments && appointments.length > 0 && experiments) //(experiments && graphType == "HistogramStack") 
        {
            const newData = []
            const experimentsList = Object.keys(experiments)
            const startDateFilter = currentStartDateFilter || moment(appointments[0]["startDate"]);
            const stopDateFilter = currentStopDateFilter || moment(appointments[appointments.length - 1]["startDate"]);
            stopDateFilter.set({ h: 23, m: 59 })
            for (const experiment of experimentsList) {
                const experimentData = getGlobalAppointmentsHistoryDistribution(experiment, startDateFilter,
                    stopDateFilter, currentStateFilter, appointments)
                newData.push(experimentData);
            }

            setExperimentsSummaryData(newData);

        }
    }, [appointments, experiments, currentStartDateFilter, currentStopDateFilter, currentStateFilter])



    useEffect(() => {
        if (appointments == null) return;
        const usersSet = new Set();
        appointments.forEach((appointment) => {

            if (appointment.attendee != "CODE_UNBOOKED") {
                usersSet.add(appointment.attendee)
            }
        });

        usersSet.forEach((user) => {
            //console.log("DDDD:Provo a caricare i dati dell'utente -> ", user);
            dispatch(UsersActions.willGetUser(user));
            dispatch(UsersActions.willGetUserProfileAsDirector(user));
        })
    }, [appointments])

    useEffect(() => { 
        //console.log(`Amount of teachers:`, amountOfTeachers); 
    }, [amountOfTeachers])

    useEffect(()=>{
        dispatch(AppointmentActions.willLoadAppointments(
            { "start": "1900-01-20T10:00:00.000Z", "end": "2050-01-20T10:00:00.000Z" }
          ));
    },[])
    
    const getFilteredAppointments = (experimentID, startDate, stopDate, appointmentState, myApps) => {
        // //console.log(`CurrentStartDateFilter:${startDate}, CurrentStopDateFilter:${moment(stopDate)}`);
        return (myApps || appointments).filter((app) => {
            let result = app["title"] == experimentID
            result = result && (startDate == null || moment(app["startDate"]).isSameOrAfter(moment(startDate)));
            result = result && (stopDate == null || moment(app["stopDate"]).isSameOrBefore(moment(stopDate)))

            result = result &&
                (// inserisco non prenotati  se ho selezionato "Tutti o Non Prenotati"
                    (["", null, "CODE_UNBOOKED"].includes(app["attendee"]) && ["0", "2"].includes(appointmentState))
                    ||
                    // inserisco i prenotati se ho selezionato "Tutti o Prenotati"
                    (!["", null, "CODE_UNBOOKED"].includes(app["attendee"]) && ["0", "1"].includes(appointmentState))
                )
            return result;
        })
    }

    const getGlobalAppointmentsHistoryDistribution = (experimentID, startDate, stopDate, appointmentState, myApps) => {
        const orderedAppointments = getFilteredAppointments(experimentID, startDate, stopDate, appointmentState, myApps).sort(
            (app1, app2) => { return (app1!=null && app2!=null && moment(app1["startDate"]).isSameOrBefore(moment(app2)) ? 1 : -1) }
        )

        ////console.log(`Exp summary data for exp (appointments):${experimentID}`, appointments);
        ////console.log(`Exp summary data for exp (orderedAppointments):${experimentID} startDate:${startDate}, stopDate:${stopDate} `, orderedAppointments);
        let experimentData = {}
        for (let i = 0; i < orderedAppointments.length; i++) {
            const key = `${moment(orderedAppointments[i]["startDate"]).format("YYYY-MM")}`

            if (experimentData[key] == null) {
                experimentData[key] = 1
            }
            else experimentData[key] += 1

        }

        // integro experimentData con le chiavi di eventuali date degli esperimenti all'interno del range 
        // [startDate,stopDate] non presenti nel dizionario
        const globalStartDate = startDate || (orderedAppointments.length > 0 && moment(orderedAppointments[0]["startDate"]).format("YYYY-MM"))
        const globalStopDate = stopDate || (orderedAppointments.length > 0 && moment(orderedAppointments[0]["stopDate"]).format("YYYY-MM"))
        let currentDate = moment(globalStartDate).startOf("month");
        const endDate = moment(globalStopDate).endOf("month")
        //console.log(`Exp summary data for exp (experimentData):${experimentID}`, experimentData);
        let experimentGlobalData = {}
        while (currentDate.isSameOrBefore(endDate)) {
            experimentGlobalData[`${moment(currentDate).format("YYYY-MM")}`] = experimentData[`${moment(currentDate).format("YYYY-MM")}`] || 0
            currentDate = moment(currentDate).add(1, "months")
        }
        //console.log(`Exp summary data for exp (experimentGlobalData):${experimentID}`, experimentGlobalData);
        // elenco di tutti gli ammontare  dell'esperimento dal più vecchio a quello più recente
        const orderedEntries = Object.entries(experimentGlobalData).sort(([a,], [b,]) => {
            return (moment(a).isBefore(moment(b)) ? -1 : 1)
        })


        let experimentTrend = []

        for (const appointmentEntry of orderedEntries) {
            experimentTrend.push({ x: moment(appointmentEntry[0]).toDate(), y: appointmentEntry[1], experimentID: experimentID })
        }

        //console.log(`Exp summary data for exp (dopo):${experimentID}`, experimentTrend);
        return experimentTrend;
    }


    const getAppointmentsHistoryDistribution = (experimentID, startDate, stopDate, appointmentState) => {
        const orderedAppointments = getFilteredAppointments(experimentID, startDate, stopDate, appointmentState).sort(
            (app1, app2) => { return app1!=null && app2!=null && moment(app1["startDate"]).isSameOrBefore(moment(app2)) }
        )

        let experimentData = {}
        for (let i = 0; i < orderedAppointments.length; i++) {
            const key = moment(orderedAppointments[i]["startDate"]).format("YYYY-MM")

            if (experimentData[key] == null) {
                experimentData[key] = 1
            }
            else experimentData[key] += 1

        }

        let experimentTrend = []
        for (const appointmentEntry of Object.entries(experimentData)) {
            experimentTrend.push({ x: moment(appointmentEntry[0]).toDate(), y: appointmentEntry[1], experimentID: experimentID })
        }

        ////console.log(`Exp data for exp:${experimentID}`, experimentTrend);
        return experimentTrend;
    }

    const getAmountOfAppointments = (experimentID, startDate, stopDate, appointmentState) => {
        const results = getFilteredAppointments(experimentID, startDate, stopDate, appointmentState);
        ////console.log("FilteredAppointments:", appointmentState, results.length, results)
        calculateAmountOfTeachersAndSchools(experimentID, results);
        return results.length;

    }

    const union = (setA, setB) => {
        const _union = new Set(setA);
        for (const elem of setB) {
            _union.add(elem);
        }
        return _union;
    }

    const getTotalAmountOfTeachers = () => {
        let finalSet = new Set();
        for (const mySet of Object.values(amountOfTeachers)) {
            finalSet = union(mySet["set"], finalSet)
        }
        return finalSet.size;
    }

    const getTotalAmountOfSchools = () => {
        let finalSet = new Set();
        for (const mySet of Object.values(amountOfSchools)) {
            finalSet = union(mySet["set"], finalSet)
        }
        return finalSet.size;
    }

    const calculateAmountOfTeachersAndSchools = (experimentId, appointments) => {
        let camountOfTeachers = new Set();
        let camountOfSchools = new Set();
        for (let i = 0; i < appointments.length; i++) {
            if (!["", null, "CODE_UNBOOKED"].includes(appointments[i]["attendee"])) {

                // aggiungo l'id del docente che ha prenotato all'insieme dei docenti per
                // uno specifico laboratorio
                camountOfTeachers.add(appointments[i]["attendee"]);

                const attendeeProfile = (users) ? users["userProfiles"][appointments[i]["attendee"]] : null;
                if (attendeeProfile) {
                    camountOfSchools.add(attendeeProfile["school"])
                }
            }

        }
        setAmountOfTeachers(existingValues => ({
            ...existingValues, [experimentId]: {
                "set": camountOfTeachers,
                "size": camountOfTeachers.size
            }
        }));
        setAmountOfSchools(existingValues => ({
            ...existingValues, [experimentId]:
            {
                "set": camountOfSchools,
                "size": camountOfSchools.size
            }
        }));

    }

    const getAppointmentsDistribution = (startDate, stopDate, appointmentState) => {
        return Object.entries(experiments).map(experiment => {
            return {
                ...experiment[1], "count": getAmountOfAppointments(experiment[0], startDate, stopDate, appointmentState)
            };
        })
    }


    //console.log("Distribuzione:", amountOfAppointments);

    const handleDateStartChange = (newDate) => {
        //console.log(`selezionata DateStart: ${newDate}`);
        try {
            const newDateStart = moment(newDate, DATE_TIME_FORMAT);
            if (newDateStart.isValid()) {
                setCurrentStartDateFilter(newDateStart)
            }
            else {
                setCurrentStartDateFilter(null)
            }

        } catch (error) {
            //console.log(`Invalid DateStart: ${newDate} -> ${error}`);
            setCurrentStartDateFilter(null);
        }

    }

    const handleDateStopChange = (newDate) => {
        //console.log(`selezionata DateStop: ${newDate}`);
        try {
            const newDateStop = moment(newDate, DATE_TIME_FORMAT);
            newDateStop.set({ h: 23, m: 59 })

            //console.log(`new date stop:${newDateStop.isValid()}`, newDateStop.format())
            if (newDateStop.isValid()) {
                setCurrentStopDateFilter(newDateStop)
            }
            else {
                setCurrentStopDateFilter(null)
            }

        } catch (error) {
            //console.log(`Invalid DateStop: ${newDate} -> ${error}`);
            setCurrentStateFilter(null);
        }

    }


    const handleStateChange = (ev) => {
        setCurrentStateFilter(ev.target.value)
        //console.log("currentstatefilter:", ev.target.value);
    }

    const renderFilterPanel = () => {
        return (

            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
                <div><b>{t("Da")}</b>
                    <DateTime inputProps={{ disabled: false }}
                        onChange={handleDateStartChange}
                        dateFormat={DATE_FORMAT}
                        timeFormat={false}
                    />

                    <Label style={{ marginTop: "10px" }} for="inputSessionState">
                        <b>{t("Stato delle sessioni")}</b></Label>

                    <Input id="inputSessionState"
                        disabled={false}
                        value={`${currentStateFilter}`}
                        onChange={handleStateChange}
                        type="select">
                        <option key="0" value="0">{t("Tutte")}</option>
                        <option key="1" value="1">{t("Prenotate")}</option>
                        <option key="2" value="2">{t("Non prenotate")}</option>

                    </Input>
                </div>
                <div><b>{t("A")}</b>
                    <DateTime inputProps={{ disabled: false }}
                        onChange={handleDateStopChange}
                        dateFormat={DATE_FORMAT}
                        timeFormat={false}
                    />

                    <Label style={{ marginTop: "10px" }} for="inputGraphType">
                        <b>{t("Tipo di grafico")}</b></Label>

                    <Input id="inputGraphType"
                        disabled={false}
                        value={graphType}
                        onChange={(ev) => { setGraphType(ev.target.value); 
                            }}
                        type="select">
                        <option key="0" value="Pie">{t("Diagramma a torta")}</option>
                        <option key="1" value="Histogram">{t("Distribuzione mensile")}</option>
                        <option key="2" value="HistogramStack">{t("Riepilogo mensile")}</option>
                    </Input>
                </div>
            </div>

        )
    }

    const handleZoom = (domain) => {
        setSelectedDomain(domain);
    }

    const handleBrush = (domain) => {
        setZoomDomain(domain);
    }

    const handleSummaryZoom = (domain) => {
        setSelectedSummaryDomain(domain);
    }

    const handleSummaryBrush = (domain) => {
        setZoomSummaryDomain(domain);
    }

    const renderSessionsSummary = () => {
        return (<div>
            <VictoryChart width={700} height={470} scale={{ x: "time" }}
                domainPadding={20}
                containerComponent={
                    <VictoryZoomVoronoiContainer
                        zoomDimension="x"
                        zoomDomain={zoomSummaryDomain}
                        onZoomDomainChange={handleSummaryZoom}
                        labels={({ datum }) => { return (false && datum.y > 0) ? ` (${datum.y}) ${experiments[datum.experimentID]["titolo"].slice(0, 20)}${experiments[datum.experimentID]["titolo"].length > 20 ? '...' : ''}` : "" }}
                        labelComponent={
                            <VictoryTooltip pointerLength={0} center={{x:100,y:100}}  flyoutWidth={200} flyoutPadding={10} style={{fontSize: '16px'}}
                             
                            />
                          }
                    />
                }
            >

                <VictoryStack >
                    {
                        experimentsSummaryData.map((data, i) => {
                            //console.log(`CM debug len:${experimentsSummaryData.length} data[${i}]: currentData:`, data)
                            return (
                                data && <VictoryBar
                                    style={{ data: { fill: `${colorsMap[data[0]["experimentID"]]}` } }}
                                    data={data} key={i} />
                            )
                        })
                    }

                </VictoryStack>
            </VictoryChart>
            <VictoryChart domainPadding={20}
                padding={{ top: 0, left: 50, right: 50, bottom: 30 }}
                width={700} height={100} scale={{ x: "time" }}
                containerComponent={
                    <VictoryBrushContainer
                        brushDimension="x"
                        defaultBrushArea="all"
                        onBrushCleared={(domain) => { }}
                        brushDomain={selectedSummaryDomain}
                        onBrushDomainChange={handleSummaryBrush}
                    />
                }
            >
                <VictoryAxis
                    tickFormat={(x) => new Date(x).getFullYear()}
                />
                <VictoryStack>
                    {
                        experimentsSummaryData.map((data, i) => {
                            return (
                                data && <VictoryBar style={{ data: { fill: `${colorsMap[data[0]["experimentID"]]}` } }}
                                    data={data} key={i} />
                            )

                        })
                    }

                </VictoryStack>
            </VictoryChart>
        </div>)
    }

    const renderSessionsTrend = () => {
        return (
            <div>
                <VictoryChart width={700} height={470} scale={{ x: "time" }}
                    domainPadding={20}
                    containerComponent={
                        <VictoryZoomContainer
                            zoomDimension="x"
                            zoomDomain={zoomDomain}
                            onZoomDomainChange={handleZoom}
                        />
                    }
                >
                    {
                        experimentTrendData && experimentTrendData[0] &&
                        <VictoryBar style={{ data: { fill: `${colorsMap[experimentTrendData[0]["experimentID"]]}` } }} data={experimentTrendData} />
                    }

                    {/* 
                    <VictoryLine
                        style={{
                            data: { stroke: "tomato" }
                        }}
                        data={experimentTrendData}
                        x="x"
                        y="y"
                    />
                    */}
                </VictoryChart>
                <VictoryChart domainPadding={20}
                    padding={{ top: 0, left: 50, right: 50, bottom: 30 }}
                    width={700} height={100} scale={{ x: "time" }}
                    containerComponent={
                        <VictoryBrushContainer
                            brushDimension="x"
                            defaultBrushArea="all"
                            onBrushCleared={(domain) => {}}
                            brushDomain={selectedDomain}
                            onBrushDomainChange={handleBrush}
                        />
                    }
                >
                    <VictoryAxis
                        tickFormat={(x) => new Date(x).getFullYear()}
                    />
                    {
                        experimentTrendData && experimentTrendData[0] &&
                        <VictoryBar style={{ data: { fill: `${colorsMap[experimentTrendData[0]["experimentID"]]}` } }} data={experimentTrendData} />
                    }
                </VictoryChart>
            </div>
        );
    }

    const renderResults = () => {
        return (
            <Form>
                <FormGroup check>
                    {renderRadioResults()}
                </FormGroup>
            </Form>
        )
    }
    const renderRadioResults = () => {
        return pieData.map((d, index) => {
            const dataY = d["y"] < 10 ? `0${d["y"]}` : d["y"];
            return <div key={index} onChange={(ev) => {
                setSelectedExperiment(experiments[ev.target.value])
                //console.log("Event Radio changed:", experiments[ev.target.value])
            }

            }>
                <Label check data-type="info"
                    style={{ marginBottom: "10px", "cursor": "pointer" }}
                    data-html={true} data-for={`session_result_${index}`}
                    data-tip={`${t("Docenti coinvolti")}: ${amountOfTeachers[d["id"]]["size"]}<br>${t("Scuole coinvolte")}: ${amountOfSchools[d["id"]]["size"]}`} >
                    {graphType == "Histogram" && (
                        <Input
                            name="radio1"
                            type="radio"
                            defaultChecked={selectedExperiment != null && selectedExperiment["id"] == d["id"] ? "checked" : ""}
                            value={d["id"]}
                        />

                    )
                    }


                    <Label check style={{
                        "padding": "5px", "color": "white",
                        "background": colors[index % colors.length]
                    }}>{dataY}</Label>{"  "}{experiments[d["id"]]["titolo"]}</Label>
                <ReactTooltip id={`session_result_${index}`} />
            </div>
        })

    }

    return (experiments &&
        <Card className="mb-4" style={{ borderColor: "#007bff" }}>
            {/* 
              <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
               <CardTitle tag="h5">Analisi delle sessioni sincrone</CardTitle>  
              </CardHeader>
                */}
            <CardBody>
                <div style={{ marginLeft: "10px", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
                    <div>
                        <Card className="mb-4" style={{ borderColor: "#007bff" }}>
                            <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
                                <CardTitle tag="h6" className="text-center">{t("Filtro di ricerca")}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {renderFilterPanel()}
                            </CardBody>
                        </Card>
                        <Label>
                            <b>{t("Totale risultati")}:{` `}
                                {amountOfAppointments.reduce(
                                    (acc, res) => { return acc + res.count }, 0)
                                }
                            </b>
                        </Label><br></br>
                        <Label>
                            <b>{t("Laboratori coinvolti")}:{` `}
                                { pieData ? pieData.length : 0
                                }
                            </b>
                        </Label><br></br>
                        <Label>
                            <b>{t("Docenti coinvolti")}:{` `}
                                {
                                    getTotalAmountOfTeachers()
                                }
                            </b>
                        </Label><br></br>
                        <Label>
                            <b>{t("Scuole coinvolte")}:{` `}
                                {
                                    getTotalAmountOfSchools()
                                }
                            </b>
                        </Label>
                        <br></br><br></br>

                        {renderResults()}
                    </div>

                    <div style={{ border: "1px solid black" }}>
                        <Card className="mb-4" style={{ borderColor: "#007bff" }}>
                            <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
                                <CardTitle tag="h6" className="text-center"> {t("Distribuzione Sessioni sincrone")}</CardTitle>
                            </CardHeader>
                            {selectedExperiment && graphType == "Histogram" &&
                                (
                                    <center>
                                        <CardText style={
                                            { fontSize: 18, marginLeft: "10px", marginTop: "10px", marginRight: "10px", fontWeight: "bold" }}>
                                            {selectedExperiment["titolo"]}
                                        </CardText>
                                    </center>

                                )}

                            <CardBody style={{ display:"flex", justifyContent:"center",  width: '100%', maxWidth:"800px"}}>
                            <div style={{ width: '100%' }}>
                                {
                                    (pieData.length <= 0) ? <Alert className={"text-center"}>{t("Non è stata trovata nessuna sessione sincrona che soddisfi i criteri di ricerca")}</Alert>
                                        :
                                        graphType == "Pie" ?
                                            (
                                                <VictoryPie
                                                    padAngle={2}
                                                    style={{
                                                        data: {
                                                            fillOpacity: 0.9, stroke: "#c43a31", strokeWidth: 0
                                                        },
                                                        labels: {
                                                            fontSize: 20, fill: "#c43a31"
                                                        }
                                                    }}
                                                    colorScale={colors}
                                                    data={pieData} />
                                            )

                                            : (graphType == "Histogram" ? renderSessionsTrend() : renderSessionsSummary())

                                }
                                </div>
                            </CardBody>
                            {
                                graphType == "Histogram" && (
                                    <CardFooter>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                                            <Label>
                                                <b>{t("Docenti coinvolti")}:{` `}
                                                    {selectedExperiment ? amountOfTeachers[selectedExperiment["id"]]["size"] : 0
                                                    }
                                                </b>
                                            </Label>

                                            <Label>
                                                <b>{t("Scuole coinvolte")}:{` `}
                                                    {selectedExperiment ? amountOfSchools[selectedExperiment["id"]]["size"] : 0
                                                    }
                                                </b>
                                            </Label>

                                            <br></br>
                                        </div>
                                    </CardFooter>

                                )
                            }

                        </Card>

                    </div>

                </div>
            </CardBody>
        </Card>)
}

