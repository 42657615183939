import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { CardTitle } from 'reactstrap';
import ReactTooltip from "react-tooltip";
//import update from 'immutability-helper';
import { default as updateArray } from 'react-addons-update';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import ControlsPanel from './RialeVideoSyncPlayerControls';
import { TraceEvent } from './TimelineWatcher';
import i18next from 'i18next';

export default class RialeVideoSyncPlayer extends Component {

  constructor(props) {
    super(props);
    const MAX_PLAYERS = this.props.maxPlayers == null ? 4 : this.props.maxPlayers;
    this.players = [];
    this.playerPanels = [];
    for (let i = 0; i < MAX_PLAYERS; i++) {
      this.players[i] = React.createRef();
      this.playerPanels[i] = React.createRef();
    }

    this.state = {
      MAX_PLAYERS: MAX_PLAYERS,
      items: this.props.items,
      playerState: Array(MAX_PLAYERS).fill({ played: 0 }),
      ready: Array(MAX_PLAYERS).fill(false),
      //ended:Array(MAX_PLAYERS).fill(false),
      muted: Array(MAX_PLAYERS).fill(false),
      playerEnter: Array(MAX_PLAYERS).fill(false),
      audioSolo: false,
      pip: Array(MAX_PLAYERS).fill(false),
      isFullScreen: false,
      fullScreenDomNode : null,
      volume: Array(MAX_PLAYERS).fill(0.5),
      controls: false,
      light: false,
      played: 0,
      loaded: 0,
      duration: 0,
      loop: false,
      loading: Array(MAX_PLAYERS).fill(false),
      buffering: Array(MAX_PLAYERS).fill(false),
      isPlaying: Array(MAX_PLAYERS).fill(false),
      seeking: Array(MAX_PLAYERS).fill(false),
      playerIndexInFullscreenMode : -1,
      subtitles: Array(MAX_PLAYERS).fill(true),
      onProgressLocked: false
    }

    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        //this.setState(updateArray(this.state, {pip: {[index]:{$set:!this.state.pip[index]}}}))

        if (this.state.isFullScreen != screenfull.isFullscreen) {
          //console.log(`Am I fullscreen -> ${screenfull.isFullscreen} State: ${this.state.isFullScreen}`);
          this.setState({ isFullScreen: screenfull.isFullscreen });
          this.props.onEventToWatch && this.props.onEventToWatch(
            TraceEvent.FULLSCREEN_VIDEO_STATUS_CHANGE, {
            "enabled": screenfull.isFullscreen
          });

        }
      })
    }
  }

  componentDidMount() {
    //console.log("In componentDidMount di RialeVideSyncPlayer");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.items != this.props.items) {
      const videocodeUpdateChanged = (prevProps.videoUpdateCode != this.props.videoUpdateCode);
      //console.log(`RVS: Intercettata MODIFICA ITEMS all'istante ${this.props.currentPositionDate}: Imposto un LOADING A TRUE (videoCodeUpdateChanged:${videocodeUpdateChanged}) ora: ${this.props.items.length} prima: ${prevProps != null ? prevProps.items.length : "undefined"}`);

      // mi faccio dare il nuovo array di videoItems con l'ordine corretto
      // in modo da mantenere i video non modificati nella stessa traccia in cui si trovano
      let { newVideos, newLoading } = this.getNewVideos(this.props.items);
      // DEBUG
      //console.log(`RVS: I video arrivati da props sono ${this.props.items.length}`);
      //console.log(`RVS: Array di newVideos aggiornato:`)
      for (let v = 0; v < newVideos.length; v++) {
        let videoId = newVideos[v] == null ? "undefined" : newVideos[v]["id"];
        //console.log(`RVS: Video n.${v} -> ${videoId}`);
      }
      // End of DEBUG
      let loadings = [];
      for (let i = 0; i < newLoading.length; i++) {
        loadings[i] = newLoading[i] == null ? false : newLoading[i];
      }
      this.props.onStartLoading();
      this.setState({
        loading: loadings
      },

        () => {
          //console.log(`(RVS) Item modificati. Numero di item: ${this.props.items.length}`);
          this.setState({ items: newVideos }
            , () => {
              this.handleEnded(); //[COMMENTATO PER DEBUG]
              // l'aggiornamento della posizione la impongo solo se sto 
              // usando il player esterno
              if (videocodeUpdateChanged) { 
               
                this.setState({onProgressLocked:true }, () =>{
                  //console.log("HPD: VIDEOCODEUPDATE STATE CHANGED -> onProgressLocked=true");
                  this.updateVideoPositions(true); 
                });
               
              } // aggiunta successiva 
            }
          );
        }
      );
    }
    // gli item non sono cambiati rispetto a prima ma è stata selezionata una nuova posizione della timeline da parte dell'utente
    else if (prevProps.videoUpdateCode != this.props.videoUpdateCode && this.props.items.length > 0) {
      //console.log(`HPD:time da RVS: (componentDidUpdate) VideoItems gli stessi di prima, LOADING:${this.state.loading} MA VideoUpdateCode passato da ${prevProps.videoUpdateCode} a ${this.props.videoUpdateCode}`);
      this.props.onStartLoading();
      // aggiorno la posizione di tutti i video
      this.updateVideoPositions();
    }
  }


  getNewVideos = (propsVideos) => {
    // faccio una copia di tutti i video che risultano attivi sulla timeline
    let propsVideoCopy = Array.from(propsVideos);
    const { items } = this.state;
    let newVideos = []
    let newLoading = []
    // passo in rassegna tutti i video che ho correntemente registrati sul player
    for (let i = 0; i < items.length; i++) {
      // se il video corrente è ancora tra quelli nuovi lo confermo nella nuova lista nella stessa posizione di prima
      // specificando che NON ho bisogno di ricaricarlo a meno che non sia cambiato qualche parametro temporale
      const confirmedVideo = propsVideos.filter(item => items[i] != null && `${item["id"]}`.localeCompare(`${items[i]["id"]}`) == 0)[0];
      if (confirmedVideo != null) {
        //console.log(`RVS: Riconfermo il video ${confirmedVideo["id"]} nella posizione ${i} `);
        newVideos[i] = confirmedVideo;
        // false purchè non siano cambiati start_time, start_offset o end_offset
        newLoading[i] = !moment(items[i].start_time).isSame(confirmedVideo.start_time) ||
          !moment(items[i].start_offset).isSame(confirmedVideo.start_offset) ||
          !moment(items[i].end_offset).isSame(confirmedVideo.end_offset)
        // rimuovo dall'array dei video quello che era ancora presente e che ho riconfermato rispetto a quelli precedenti
        propsVideoCopy = propsVideoCopy.filter((item, index) => `${item["id"]}`.localeCompare(`${items[i]["id"]}`) != 0)
        // DEBUG
        //console.log(`RVS: Elenco dei video ancora rimasti nella Timeline per eventuali riconferme o aggiunte (${propsVideoCopy.length})`)
        for (let v = 0; v < propsVideoCopy.length; v++) {
          //console.log(`RVS:  ${v} -> ${propsVideoCopy[v]["id"]}`);
        }
        // end of DEBUG
      }
    }

    // a questo punto in newVideos ci sono tutti i video in comune con lo stato precedente
    // mentre su propsVideoCopy sono rimasti solo quelli che devo ancora aggiungere
    // non mi rimane che aggiungere i nuovi video negli slot rimasti vuoti (o aggiungerne all'accorrenza)
    // specificando che andranno caricati (loading:true)
    for (let i = 0; i < propsVideoCopy.length; i++) {
      let slot_found = false; // per il momento non ho trovato alcuno slot
      // cerco il primo slot libero dove collocare il nuovo item
      for (let j = 0; j < newVideos.length; j++) { // se ho trovato uno slot libero, gli assegno il nuovo item e passo al prossimo
        if (newVideos[j] == null) {
          //console.log(`Mi risulta che newVideos[${j}] sia null: gli assegno il video ${propsVideoCopy[i]["id"]}`)
          newVideos[j] = propsVideoCopy[i]
          newLoading[j] = true; // prima era erroneamente indicato l'indice "i" ...!?!
          slot_found = true; // indico che ho trovato lo slot per il video propsVideoCopy[i]
          break;
        }
      }
      // se NON ho trovato uno slot disponibile sono costretto ad aggiungere a newVideos il nuovo item
      // specificando che lo dovro' far caricare
      if (!slot_found) {
        newVideos.push(propsVideoCopy[i]);
        newLoading.push(true);
      }
    }

    // imposto al valore false evenuali slot vuoti di video
    for (let i = 0; i < newLoading.length; i++) { if (newLoading[i] == null) newLoading = false; }

    //console.log("Video props pervenute")
    //console.log(propsVideos);
    //console.log("Video items correnti");
    //console.log(items)
    //console.log("Video items candidati");
    //console.log(newVideos)
    //console.log("Array dei video da caricare (loading)")

    //console.log(newLoading)
    return { newVideos, newLoading };
  }


  /** 
   * Aggiorna la posizione dei video sulla base dell'istante di tempo corrente sulla timeline
   */
  updateVideoPositions = (locked) => {

    const { currentPositionDate } = this.props;
    //console.log(`CHIAMATO updateVideoPositions da ${currentPositionDate}`);

    // se ho N video e non sono tutti pronti non aggiorno la posizione
    if (this.isSomeVideoNotReadyYet()) {
      //console.log("HPD:Operazione di SEEK annullata in quanto i video non sono ancora tutti pronti (sblocco l'onProgressLock");
      this.props.onStartLoading();
      this.setState({onProgressLocked: false})
      return;
    }
    // @audit info debug
    ////console.log("Valore del player 0:",this.players[0].current.player.constructor.name);
    
    /*
    if (this.players[0].current.player == null) {
      //console.log("HPD: Valore del player nullo. Annullo la updatePosition del video");
      if (locked)
      {
        //console.log("HPD: locked:true -> sblocco il lock dell'onProgress...");
        this.setState({onProgressLocked: false});
      }
      
      return;
    }
    */
    //console.log(`Numero di video items in state: ${this.state.items.length}`);
    for (let i = 0; i < this.state.MAX_PLAYERS; i++) {
      // se un player risulta nullo, analizzo gli altri
      if (this.players[i].current==null) 
      {
        continue;
      }

      //console.log(`Valore del player ${i}:`, this.players[i].current);
      // aggiorno la posizione del video i-esimo
      if (this.state.items[i] != null) {
        const videoPos1 = moment(currentPositionDate).diff(this.state.items[i].start_time);
        const videoPos1InSeconds = parseInt(videoPos1 / 1000);
        //console.log(`HPD: Richiedo un seek con richiesta locked:${locked} per la posizione video ${i} (READY: ${this.state.ready[i]}`);
        //console.log(`duration:${this.players[i].current.getDuration()}):${videoPos1InSeconds + this.state.items[i].start_offset} secs.`);
        
        this.setState(updateArray(this.state, { seeking: { [i]: { $set: true } } }),
          () =>
          this.state.items[i]!=null && this.players[i].current.seekTo(parseFloat(videoPos1InSeconds + this.state.items[i].start_offset), 'seconds'));
      }
    }
    if (locked)  {
      //console.log("HPD: sblocco l'onProgressLocked");
      this.setState({onProgressLocked: false})
    }
  }

  /** 
    * Ricalcola la posizione della timeline corrispondente alla posizione corrente di un item e la comunica al parent
    */
  updateCurrentPositionDate = (item, playerState, itemIndex) => {

    if (this.areOtherVideosEnded(itemIndex) || this.videoEndsAfterOthers(itemIndex)) {
      const currentVideoPosition = playerState.playedSeconds;
      ////console.log(`RVS:Current video ${item.id} PLAYED SECONDS: ${currentVideoPosition} secs.`);
      const itemStartTime = item.start_time
      // offset iniziale del video (in secondi)
      const itemStartOffset = item.start_offset
      // nuova posizione del marker sulla base della posizione corrente del video selezionato 
      // e dello startOffset
      const newCurrentPositionDate = moment(itemStartTime).add(currentVideoPosition, "seconds").add(-itemStartOffset, "seconds");


      //console.log(`RVS_P: Internal progress Video ${itemIndex} newPos:${newCurrentPositionDate}-> ${this.state.items[itemIndex].title}`);
      //console.log(`RVS_P: Richiamo onProgress Video ${itemIndex} da RVS con cp:${newCurrentPositionDate}`);
      this.props.onProgress(newCurrentPositionDate, itemIndex)
    }
  }

  // se un qualsiasi video è in fase di caricamento o riposizionamento, con consento
  // la comunicazione di progress da parte degli altri video
  isHandleProgressLocked = () =>
  {
    const {onProgressLocked, ready, loading, buffering, seeking, MAX_PLAYERS } = this.state;
    
    if (onProgressLocked==true) {
      //console.log(`HPD: HANDLE PROGRESS LOCKED by onProgressLocked STATE`);
      return true;}
   /* NON NECESSARIO,,,anzi controproducente!
    for (let i = 0; i < MAX_PLAYERS; i++) {
      if (loading[i] || buffering[i] || seeking[i] || !ready[i]) 
      {//console.log(`HPD: HANDLE PROGRESS LOCKED by player ${i}`);return true;}
    }

    */
    
    return false;
  }


  //https://stackoverflow.com/questions/41807604/react-how-to-pass-arguments-to-the-callback
  handleProgress = index => state => {

    // this.setState(updateArray(this.state, {pip: {[index]:{$set:true}}}))
    //console.log(`Handle Progress playerIndex:${index}`);
    this.setState(updateArray(this.state, { loading: { [index]: { $set: false } } }),

      () => {
        this.setState(updateArray(this.state, { playerState: { [index]: { $set: state } } }),


          () => {
            if (state.loaded <= state.played || !this.videosAreNotLoading()) {
              //console.log(`(V1) Sto caricando il video ${index} con loading: ${this.state.loading}, non traccio il progress per video ${index}`);
              this.props.onStartLoading();
              return;
            }
            else {
              ////console.log(`In handleProgress Video 1: terminato? ${this.isVideo1Ended()}`);
              this.handleEnded();
              if (this.isHandleProgressLocked()===true)
              {
                //console.log(`HPD:HandleProgress annullato causa presenza video in fase di caricamento`);
              }
              else if (!this.isVideoEnded(index))
              {
                //console.log(`HPD: AUTORIZZATO`);
                this.updateCurrentPositionDate(this.state.items[index], state, index);
              }
               
              else { 
                //console.log(`HPD:Video terminato: ${index} `);
            }
            }
          })
      });
  }

  //https://scriptverse.academy/tutorials/reactjs-update-array-state.html
  handleEnablePIP = (index) => {
    this.props.onEventToWatch && this.props.onEventToWatch(
      TraceEvent.PIP_STATUS_CHANGE, {"index": index, "enabled": true });
    this.setState(updateArray(this.state, { pip: { [index]: { $set: true } } }))
  }

  handleDisablePIP = (index) => {
    this.props.onEventToWatch && this.props.onEventToWatch(
      TraceEvent.PIP_STATUS_CHANGE, { "index": index, "enabled": false });
    this.setState(updateArray(this.state, { pip: { [index]: { $set: false } } }))
  }

  handleTogglePIP = (index) => {
    //console.log("RICHIAMATO HANDLE TOGGLE PIP");
    this.props.onEventToWatch && this.props.onEventToWatch(
      TraceEvent.PIP_STATUS_CHANGE, { "index": index, "enabled": !this.state.pip[index] });

    this.setState(updateArray(this.state, { pip: { [index]: { $set: !this.state.pip[index] } } }))
  }

  handleToggleSubtitles = (index) => {
    //console.log(`RICHIAMATO HANDLE TOGGLE SUBTITLES che imposto a:${!this.state.subtitles[index]}` );
    //this.props.onEventToWatch && this.props.onEventToWatch(
    //  TraceEvent.PIP_STATUS_CHANGE, { "index": index, "enabled": !this.state.pip[index] });

    this.setState(updateArray(this.state, { subtitles: { [index]: { $set: !this.state.subtitles[index] } } }), ()=>
    {
      // Patch per triggerare correttamente il cambio di stato dei sottotitoli
      if (this.state.isPlaying[index]) {this.props.onPauseTimeline(); setTimeout(this.props.onPlayTimeline,500)};
      }
    )
  }

  handleReady = (index) => {
    //console.log(`HANDLE READY ${index} con duration:${this.players[index].current.getDuration()} loading: ${this.state.loading}`);

    this.setState(
      updateArray(this.state, { ready: { [index]: { $set: true } } }),
      () => {
        if (this.state.loading[index]) {
          //console.log(` Sono in loading ${index}: READY:${this.state.ready}:Aggiorno la posizione del video sulla base del currentDatePosition`);
          this.setState(
            updateArray(this.state, { loading: { [index]: { $set: false } } }),

            () => this.updateVideoPositions());
        }
      });
  }


  handleDuration = index => (duration) => {
    //console.log(`onDuration ${index}: ${duration}`)
    this.setState({ duration })
  }

  handleSeek = index => (t_sec) => {
    //console.log("On seek 1 (Notifico fine fase di caricamento)->", t_sec);
    this.setState(updateArray(this.state, { seeking: { [index]: { $set: false } } }));
  }

  handleStart = index => () => {
    //console.log(`START VIDEO ${index}`);
  }


  handlePlay = index => () => {
    this.setState(updateArray(this.state,
      {
        isPlaying: { [index]: { $set: true } },
        buffering: { [index]: { $set: false } }
      }))
  }


  handlePause = index => () => {
    //console.log(`onPause ${index} alla posizione ${this.players[index].current.getCurrentTime()} secs.TimelinePlaying:${this.props.timelinePlaying}`);
    this.setState(updateArray(this.state, { isPlaying: { [index]: { $set: false } } }));
  }


  handleBuffer = index => () => {
    //console.log(`BUFFER video ${index} -> true`);
    this.setState(updateArray(this.state, { buffering: { [index]: { $set: true } } }));
  }


  handleBufferEnd = index => () => {
    //console.log(`BUFFER END video ${index} -> false: READY:${this.state.ready[index]}`);
    this.setState(updateArray(this.state, { buffering: { [index]: { $set: false } } }));
  }

  onEnded = (index) => {
    //console.log(`Ended ${index}`);
    //console.log(`Gestione di fine video: FS:${this.state.isFullScreen} END OF index:${index}  FS_PIndex:${this.state.playerIndexInFullscreenMode}`);
    // se sono in modalità fullscreen e il video terminato è quello in fullscreen
    // rimuovo la modalità di fullscreen (per evitare di vedere uno schermo nero)
    if (this.state.isFullScreen &&  this.state.playerIndexInFullscreenMode==index)
    { 
      //console.log("Rimuovo il fullscreen!");
      this.toggleFullScreen(index)
    }

    this.removeVideo(index);
  }


  removeVideo = (index) => {
    //this.setState(updateArray(this.state, {volume: {[playerIndex]:{$set:newVolume}}}));
    this.setState(updateArray(this.state, {
      // ended: {[index]:{$set:true}}, // TEST PER DEBUG
      items: { [index]: { $set: null } }
    }),
      () => { this.handleEnded(); }
    );
  }


  handleEnded = () => {
    //
    if (this.areAllVideoEnded()) {
      //console.log("Tutti i video sono terminati da RVSP!!!");
      this.setState({ items: [] }, () => this.props.onEnded());
      return true;
    }
    return false;
  }

  videosAreNotLoading = () => {
    const { loading, MAX_PLAYERS } = this.state;
    for (let i = 0; i < MAX_PLAYERS; i++) {
      if (loading[i]) return false;
    }
    return true;
  }

  isSomeVideoNotReadyYet = () => {
    // se ho N video e non sono tutti pronti non aggiorno la posizione
    let anyNotReady = false;
    let activeVideos = 0;
    const { items, MAX_PLAYERS, ready } = this.state;
    for (let i = 0; i < MAX_PLAYERS; i++) {
      if (items[i] != null) {
        activeVideos += 1;
        if (!ready[i]) anyNotReady = false; // anyNotReady || true; //false; prima era false...BUG!
      }
    }
    return activeVideos > 1 && anyNotReady;
  }

  isVideoEnded = (i) => {
    const { currentPositionDate } = this.props;
    const { items } = this.state; //  {items,ended}
    const res = (
      //ended[i] || // controllo ridondante! -> ended è stato rimosso dallo state
      this.state.playerState[i].played >= 1 || items[i] == null
      || !moment(currentPositionDate).isBetween(items[i].start_time, items[i].end_time, null, '[)') // ||
    );

    //console.log(`RVS:: isVideo1Ended:${res} items[i]=${items[i]}`);
    return res;
  }

  areOtherVideosEnded = (index) => {
    for (let i = 0; i < this.state.MAX_PLAYERS; i++) {
      if (i === index || this.state.items[i] == null) continue;
      if (!this.isVideoEnded(i)) return false;
    }
    return true;
  }

  /**
   * Restituisce True se il video con indice passato come argomento termina dopo tutti gli altri.
   * N.B:Nel caso il video con indice passato come argomento termini esattamente nello stesso istante di un altro o più
   * video, la funzione restituisce convenzionalmente True se il suo indice è maggiore di quello degli altri
   * che terminano allo stesso istante (altrimenti false). Questo per garantire sempre la unicità del video considerato il MASTER
   * cioè quello che, terminando dopo gli altri, ha il ruolo di comunicare alla timeline la nuova posizione.
   * @param {*} index 
   */
  videoEndsAfterOthers = (index) => {
    let lastVideoIndexWithSameEndTime = null;
    for (let i = 0; i < this.state.MAX_PLAYERS; i++) {
      // Il confronto con se stesso non va fatto...
      if (i === index || this.state.items[i] == null) continue;
      if (moment(this.state.items[index].end_time).isBefore(this.state.items[i].end_time)) return false;
      else if (moment(this.state.items[index].end_time).isSame(this.state.items[i].end_time)) {
        ////console.log(`RVS_P: Trovato video con indice ${i} che termina allo stesso istante di quello con indice ${index}`);
        lastVideoIndexWithSameEndTime = i;
      }
    }
    if (lastVideoIndexWithSameEndTime == null)
      return true;
    else return lastVideoIndexWithSameEndTime < index;
  }

  areAllVideoEnded = () => {
    for (let i = 0; i < this.state.MAX_PLAYERS; i++) {
      if (!this.isVideoEnded(i)) return false;
    }
    return true;
  }

  toggleAudioSolo = (playerIndex) => {
    const { audioSolo } = this.state;

    this.props.onEventToWatch && this.props.onEventToWatch(
      TraceEvent.AUDIO_SOLO_STATUS_CHANGE, {
      "enabled": (!audioSolo), "index" : playerIndex
    });

    const numMutes = this.state.muted.length;
    let newMutes = [];
    for (let i = 0; i < numMutes; i++) {
      let newValue = false;
      if (!audioSolo && i != playerIndex) { newValue = true; }
      newMutes[i] = newValue;
    }
    this.setState({ audioSolo: !this.state.audioSolo, muted: newMutes });
  }


  toggleMute = (playerIndex) => {
    const newVolume = (this.state.volume[playerIndex] <= 0 ? 0.5 : 0);
    
    this.props.onEventToWatch && this.props.onEventToWatch(
      TraceEvent.VOLUME_STATUS_CHANGE, {
      "enabled": (newVolume>0), "index" : playerIndex
    });
    this.setState(updateArray(this.state, { volume: { [playerIndex]: { $set: newVolume } } }));
  }
  // see https://www.npmjs.com/package/react-easyfullscreen
  toggleFullScreen = (playerIndex) => {
    const domNode = findDOMNode(this.playerPanels[playerIndex].current)
    if (this.state.isFullScreen)
    {
      this.setState({fullScreenDomNode :null, playerIndexInFullscreenMode: -1});
    }
    else
    {
      this.setState({fullScreenDomNode:domNode,playerIndexInFullscreenMode:  playerIndex});
    }
    
    screenfull.toggle(domNode)
  }
  //Example of vtt file: "https://www.crs4.it/wp-content/uploads/2022/02/logoRiale-1024x247.png" 

  subtitlesAreAvailableInVideo = (i) => {
    const {items} = this.props; //n.b accedo alla versione dell'item aggiornata (props e non state)
    return items[i] != null && 
    (
      (items[i].subtitlesUrl!=null &&  items[i].subtitlesUrl.length>0) ||
      (items[i].subtitlesUrl_IT!=null &&  items[i].subtitlesUrl_IT.length>0)  ||
      (items[i].subtitlesUrl_EN!=null &&  items[i].subtitlesUrl_EN.length>0) 
    )
   ;
  }

  renderPlayer = (i) => {
    const { items, controls,
      muted, volume, audioSolo, pip, subtitles } = this.state;
    const currentLanguage = i18next.language || "it-IT"
    const { timelinePlaying } = this.props;
    const canPlay = timelinePlaying && this.videosAreNotLoading();
    const videoTip = (items[i] == null ? "" : items[i].description);
    ////console.log("SUBTITLES STATE:" + subtitles[0])
    const subtitlesTracks = ((subtitles[i]==true && items[i] != null && 
      (items[i].subtitlesUrl!=null || items[i].subtitlesUrl_IT!=null || items[i].subtitlesUrl_EN!=null  ) ) ? [
      {
        kind: "subtitles",
        src: items[i].subtitlesUrl && `${items[i].subtitlesUrl}`,
        srcLang: 'it',
        default: true,
        mode: `${(subtitles[i]==true && items[i] != null && items[i].subtitlesUrl!=null) ? "showing" : "hidden"}`
      },
      {
        kind: "subtitles",
        src: items[i].subtitlesUrl_IT && `${items[i].subtitlesUrl_IT}`,
        srcLang: 'it',
        default: currentLanguage=='it-IT',
        mode: `${(subtitles[i]==true && items[i] != null && items[i].subtitlesUrl_IT!=null) ? "showing" : "hidden"}`
      },
      {
        kind: "subtitles",
        src: items[i].subtitlesUrl_EN && `${items[i].subtitlesUrl_EN}`,
        srcLang: 'en',
        default: currentLanguage=='en-US',
        mode: `${(subtitles[i]==true && items[i] != null && items[i].subtitlesUrl_EN!=null) ? "showing" : "hidden"}`
      }
    ].filter(x=>x.src!=null) : [])

    return (
      <table key={i} ref={this.playerPanels[i]}>
        <tbody>
          <tr>
            <td>
              <div style={{
                flex: 1, position: "relative",
                alignItems: 'end'
              }}

                onMouseEnter={() => {
                  this.setState(updateArray(this.state,
                    {
                      playerEnter: { [i]: { $set: true } }
                    }))
                }}

                onMouseLeave={() => {
                  this.setState(updateArray(this.state,
                    {
                      playerEnter: { [i]: { $set: false } }
                    }))
                }}
              >

                {this.state.playerEnter[i] &&
                  <center>
                    <ControlsPanel 
                      fullScreenDomNode={this.state.fullScreenDomNode}
                      item={items[i]}
                      volume={volume[i]}
                      isFullscreen = {this.state.isFullScreen}  
                      timelineIsPlaying = {this.props.timelinePlaying}
                      areVideoLoading = {this.props.areVideoLoading}
                      playedSeconds = {this.state.playerState[i].playedSeconds}
                      duration= {items[i]?.end_offset || this.players[i].current.getDuration()}
                      muted={muted[i]}
                      audioSolo={audioSolo}
                      onToggleMute={() => this.toggleMute(i)}
                      onToggleAudioSolo={() => this.toggleAudioSolo(i)}
                      onTogglePip={() => this.handleTogglePIP(i)}
                      onToggleSubtitles={() => this.handleToggleSubtitles(i)}
                      onFullScreenRequest={() => this.toggleFullScreen(i)} 
                      onPauseTimeline = {() => this.props.onPauseTimeline()}
                      onPlayTimeline = {() => this.props.onPlayTimeline()}
                      onDatetimeChangeRequest= {(newPos) => this.props.onDatetimeChangeRequest(newPos)}
                      subtitlesAvailable = {this.subtitlesAreAvailableInVideo(i)}
                      subtitles={subtitles[i]}
                      />
                     
                  </center>
                }

                <ReactPlayer
                  ref={this.players[i]}

                  config={{
                    file: {
                      attributes: {
                      poster: "https://www.crs4.it/wp-content/uploads/2022/02/logoRiale-1024x247.png" ,
                        crossOrigin: "true",
                      },
                      tracks: subtitlesTracks,
                    },
                  }}
                  width='100%'
                  height='100%'
                  muted={muted[i]}
                  volume={volume[i]}
                  url={items[i] != null ? items[i].source : null}
                  progressInterval={100}
                  playing={canPlay}
                  controls={controls}
                  pip={pip[i]}
                  onEnablePIP={() => this.handleEnablePIP(i)}
                  onDisablePIP={() => this.handleDisablePIP(i)}
                  onReady={() => this.handleReady(i)}
                  onStart={this.handleStart(i)}
                  onPlay={this.handlePlay(i)}
                  onPause={this.handlePause(i)}
                  onBuffer={this.handleBuffer(i)}
                  onBufferEnd={this.handleBufferEnd(i)}
                  onSeek={this.handleSeek(i)}
                  onEnded={() => this.onEnded(i)}
                  onError={e => console.log('onError di ReactPlayer:', e)}
                  onProgress={this.handleProgress(i)}
                  onDuration={this.handleDuration(i)}
                />
              </div>
            </td>
          </tr>
          <tr data-tip={videoTip}>
            <td>
              {items[i] != null && (<CardTitle><b><center>{items[i].title}</center></b></CardTitle>)}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderVideoTip = (i) => {
    const { items } = this.state;
    const videoTip = (items[i] == null ? "" : items[i].description);

    return (<tr data-tip={videoTip}>
      <td>
        {items[i] != null && (<CardTitle><b>{items[i].title}</b></CardTitle>)}
      </td>
    </tr>)
  }

  renderAllPlayers = () => {
    return this.players.map((p, index) => this.renderPlayer(index))
  }

  render() {
    return (
      <div>

        {this.renderAllPlayers()}

        <ReactTooltip place="top" />
      </div>
    );
  }
}


