import { Auth } from 'aws-amplify';

const getIdToken = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
});

export const RIALE_VERSION: string = "2.1.2 (beta)"
export const rialeGuideUrl = (language:string) => {return (language== "it-IT" ? 
"/guide/guida_piattaforma_riale_eu_IT_v.2.0.1.pdf": 
"/guide/guida_piattaforma_riale_eu_EN_v.2.0.1.pdf")}


export const chiSiamoUrl = (language:string) => {return (language== "it-IT" ? 
"https://www.crs4.it/it/riale/" : "https://www.crs4.it/riale/")}

export const configuration: any = {

  production: {
    publicExperimentEndpoint: "https://jlbcew97h4.execute-api.eu-west-1.amazonaws.com/production/public/",
    createAnaliticsSessionEndpoint: "https://jlbcew97h4.execute-api.eu-west-1.amazonaws.com/production/public/createAnalyticsSession/",
    baseUrl: "https://riale.ideab3.it",
    rialenetBaseUrl: "https://rialenet-forum.herokuapp.com",
    resourcesBaseUrl: "https://riale.ideab3.it/resources/",
    catalog_IT: "/catalog/prod/catalog_IT.json",
    catalog_EN: "/catalog/prod/catalog_EN.json",
    analyticsSessionGroup:"PROD_01",
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_Rl7JUZZQf', //'eu-west-1_rmCpoAsRb',
      userPoolWebClientId: '6g864i59ik5oj5ie79mhspe6rg', //'10mml6g6mru35qk67tkskb8tl0',
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false
    },
    graphql_headers: getIdToken,
    'aws_appsync_graphqlEndpoint': 'https://uszydtqjffeu7ojjcumq3n4ddu.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  } ,
  beta: {
    publicExperimentEndpoint: "https://z6my85w0yl.execute-api.eu-west-1.amazonaws.com/beta/public/",
    createAnaliticsSessionEndpoint: "https://z6my85w0yl.execute-api.eu-west-1.amazonaws.com/beta/public/createAnalyticsSession/",
    baseUrl: "https://beta.riale.ideab3.it",
    rialenetBaseUrl: "https://rialenet-forum.herokuapp.com",
    resourcesBaseUrl: `https://beta.riale.ideab3.it/resources/`,
    catalog_IT: "/catalog/beta/catalog_IT.json",
    catalog_EN: "/catalog/beta/catalog_EN.json",
    catalogTmp: "https://740820033840-riale-calendar-beta-resources-gdpr.s3.eu-west-1.amazonaws.com/resources/catalog.json",
    analyticsSessionGroup:"BETA_01",
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_2mBdtDJgw', //'eu-west-1_rmCpoAsRb',
      userPoolWebClientId: '1ki4foelgc573rt0k7jdf6bupf', //'10mml6g6mru35qk67tkskb8tl0',
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false
    },
    graphql_headers: getIdToken,    
    'aws_appsync_graphqlEndpoint': 'https://wg5zw5uawrenlduk54pq7zy54a.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  } ,
  
  dev: {
    publicExperimentEndpoint: "https://i1wgp6dy3k.execute-api.eu-west-1.amazonaws.com/dev/public/",
    baseUrl: "https://dev.calendar.riale.ideab3.it",
    rialenetBaseUrl: "https://rialenet-forum.herokuapp.com",
    resourcesBaseUrl: "https://dev.calendar.riale.ideab3.it/resources/",
    catalog:"https://dev.calendar.riale.ideab3.it/resources/catalog.json",
    analyticsSessionGroup:"DEV_01",
    Auth: {

      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_5V9fbg3OY', //'eu-west-1_rmCpoAsRb',

      userPoolWebClientId: '747aot0sldjrv9uj7r1gt69pjr', //'10mml6g6mru35qk67tkskb8tl0',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false

    },
    'aws_appsync_graphqlEndpoint': 'https://2yz4fvqinzgjrmnaa6gds33p7i.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  }
}