import update, { extend } from 'immutability-helper';
import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
var _ = require('lodash');

const getTlaIndex = (tlas: any, key: any) => _.findIndex(tlas, function (tla: any) { return tla.id == key });

export const currentSlice = createSlice({
  name: 'design',
  initialState: {
    designList: [],
    edited: false,
    current: {
      tlas: []
    } as any,
    original: {}
  },
  reducers: {
    willListDesign: (state, action: PayloadAction<any>) => state,
    didListDesignSuccess: (state, action: PayloadAction<any>) => void (state.designList = action.payload),
    didListDesignFail: (state, action: PayloadAction<any>) => state,
    willGetDesign: (state, action: PayloadAction<string>) => state,
    didGetDesignSuccess: (state, action: PayloadAction<any>) => void (state.current = action.payload),
    didGetDesignFail: (state, action: PayloadAction<any>) => state,
    
    setDesignMetadata: (state, action: PayloadAction<any>) => void (state.current[action.payload.name] = action.payload.value, state.edited=true),
    setTLAMetadata: (state, action: PayloadAction<any>) => void (state.current.tlas[action.payload.tlaIndex][action.payload.name] = action.payload.value, state.edited=true),
    setActionMetadata: (state, action: PayloadAction<any>) => void (state.current.tlas[action.payload.tlaIndex].actions[action.payload.actionIndex][action.payload.name] = action.payload.value, state.edited=true),

    addAction: (state, action: PayloadAction<any>) => void(state.current.tlas[action.payload.tlaIndex].actions.push(action.payload.value), state.edited=true),
    deleteAction: (state, action: PayloadAction<any>) => void(state.current.tlas[action.payload.tlaIndex].actions.splice(action.payload.actionIndex, 1), state.edited=true),

    addTLA: (state, action: PayloadAction<any>) => void(state.current.tlas.push(action.payload.value), state.edited=true),
    deleteTLA: (state, action: PayloadAction<any>) => void(state.current.tlas.splice(action.payload.tlaIndex, 1), state.edited=true),
    
    resetEdit: (state, action: PayloadAction<any>) => void(state.edited=false, state.current = state.original),
    setEdit: (state, action: PayloadAction<any>) => void(state.edited=false, state.original = state.current),

    moveTLAComponent: (state, action: PayloadAction<any>) => {
      const source = action.payload.source;
      const destination = action.payload.destination;
      state.current = update(state.current, {tlas: {$splice: [[source, 1], [destination, 0, state.current.tlas[source]]]}})
      state.edited=true
    },
    moveActionComponent: (state, action: PayloadAction<any>) => {
      const source = action.payload.source;
      const destination = action.payload.destination;
      const tlaSource = action.payload.tlaSource;
      const tlaDestination = action.payload.tlaDestination;

      if (tlaSource == tlaDestination) {

        const tlaSourceIndex = getTlaIndex(state.current.tlas, tlaSource);
        const action = state.current.tlas[tlaSourceIndex].actions[source];
        //SHIFT ACTIONS
        state.current = update(state.current, {tlas: {[tlaSourceIndex]: {actions: {$splice: [[source, 1],[destination, 0, action]]}}}})

      }else{

        const tlaSourceIndex = getTlaIndex(state.current.tlas, tlaSource);
        const tlaDestinationIndex = getTlaIndex(state.current.tlas, tlaDestination);
        const action = state.current.tlas[tlaSourceIndex].actions[source];
        //Remove action from source
        state.current = update(state.current, {tlas: {[tlaSourceIndex]: {actions: {$splice: [[source, 1]]}}}})
        //Add action to destination
        state.current = update(state.current, {tlas: {[tlaDestinationIndex]: {actions: {$splice: [[destination, 0, action]]}}}})

      }
      state.edited=true
    }
  }
})

export const { actions, reducer }: any = currentSlice
export const {
  willListDesign,
  didListDesignSuccess,
  didListDesignFail,
  willGetDesign,
  didGetDesignSuccess,
  didGetDesignFail,
  setDesignMetadata,
  setActionMetadata,
  setTLAMetadata,
  moveTLAComponent,
  addAction,
  deleteAction,
  resetEdit
} = actions

const getTla = (state: any, index: any) =>
  state.current.tlas[index]

export const selectors = {
  getDesignList: (state: any) => state.design.designList,
  getCurrentDesign: (state: any) => state.design.current,
  isDesignEdited: (state: any) => state.design.edited,
  getTla: () => createSelector([getTla], (tla) => tla)}
