import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  Alert, Container, Row, Col, Card, CardBody, CardHeader, CardTitle,
  Nav, NavItem, NavLink,
  TabContent, TabPane
} from 'reactstrap'
import { Content } from '../components/Content';
import { ExperimentDetails } from '../components/ExperimentDetails'
import { selectors as CatalogSelectors, actions as CatalogActions } from '../store/slices/catalog'
import { selectors as ExperimentsSelector, actions as ExperimentsActions } from '../store/slices/experiments'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AppointmentsViewer from '../components/appointmentsViewer'
import AppointmentEditor from '../components/appointmentsEditor'
import { CalendarPage } from './calendar';
import InteractiveSession from '../components/InteractiveSession';
import { Header, Footer } from '../header'
import { selectors as AuthSelectors } from '../store/slices/auth'
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags'


export const DetailExperiment = () => {

  let { experiment }: any = useParams();
  const dispatch = useDispatch();
  const currentExperiment = useSelector(CatalogSelectors.getCurrentExperiment);
  const isLogged = useSelector(AuthSelectors.isLogged);
  const catalog = useSelector(CatalogSelectors.getCatalog);
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });

  //console.log("Current experiment:", currentExperiment);
  React.useEffect(() => {
    //console.log("Carico l'esperimento con id:", experiment);
    dispatch(CatalogActions.willGetExperiment(experiment))

    return () => { }
  }, []);


  React.useEffect(() => {
    //console.log("Carico l'esperimento (a causa di aggiornamento Language) con id:", experiment);
    dispatch(CatalogActions.willGetExperiment(experiment))

    return () => { }
  }, [catalog]);

  /*
  React.useEffect(()=>{
    //console.log("current experiment:", currentExperiment);
    dispatch(CatalogActions.willLoadCurrentSharedExperiments(currentExperiment));       
  }, [currentExperiment])
  */




  const timelinePubbliche = (currentExperiment != null &&
    currentExperiment.timeline_pubbliche != null) ?
    currentExperiment.timeline_pubbliche : [];

  //  [{"id":"1234", "descrizione" : "descrizione!"}] as any;

  const renderSessioniInterattive = () => {
    return currentExperiment.interactive_devices &&
      <div style={{ display: "flex", flexDirection: "column" }}>
        {
          currentExperiment.interactive_devices.map((interactive_device: any) => {
            return <InteractiveSession content={interactive_device} />
          })
        }

      </div>


  }


  const linkFormatter = (cell: any, row: any) => {
    const languages = row.languages || ["IT"]
    return <div style={{ display: "flex" }}>
      {
        languages.map((language: any) => {
          return (<Flag code={language} height="14" style={{ "marginTop": "4px", "marginLeft": "5px", "marginRight": "5px" }} />)
        })
      }

      <a href={`/public/${row.id}`}>{row.descrizione}</a>
    </div>

  }
  const columns = [
    {
      dataField: 'id',
      text: 'Id',
      sort: true,
      hidden: true
    }
    ,
    {
      dataField: 'descrizione',
      text: t('descrizione'),
      formatter: linkFormatter,
      sort: true
    }
  ]

  const renderContainer = () => {
    return (
      <Container fluid>

        <Row>
          <Col xs="12">
            {currentExperiment != null && <ExperimentDetails content={currentExperiment} />}
          </Col>
        </Row>

        {
          currentExperiment && currentExperiment.interactive_devices && currentExperiment.interactive_devices[0] &&
          <Row id="interactive_sessions">
            <Col>
              <Card className="mb-4" style={{ borderColor: "#007bff" }}>
                <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
                  <CardTitle tag="h5" className="text-center">{t("Sessioni pratiche")}</CardTitle>
                </CardHeader>
                <CardBody>
                  {renderSessioniInterattive()}
                </CardBody>
              </Card>

            </Col>
          </Row>
        }
        {timelinePubbliche != null && timelinePubbliche.length > 0 && isLogged &&
          (<Row id="timelines">
            <Col xs="12">
              <Card className="mb-4" style={{ borderColor: "#007bff" }}>
                <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
                  <CardTitle tag="h5" className="text-center">{t("Timeline pubbliche")}</CardTitle>
                </CardHeader>
                <CardBody>
                  <BootstrapTable bootstrap4 keyField='id' data={
                    timelinePubbliche
                  }
                    columns={columns} />
                </CardBody>
              </Card>
            </Col>
          </Row>)
        }
        <div id="calendar">
          {isLogged ? (
            <CalendarPage hideMenu={true} experimentId={experiment} editorDisabled={!isLogged} />
          ) :
            <Alert color="warning" className="text-center">
              <Link to="/login">
                {t("alert_access_to_timeline")}
              </Link>
            </Alert>
          }
        </div>


        <Row>
          <Col xs="12">
            <Footer />
          </Col>
        </Row>
      </Container>)
  }

  return (
    <>
      <Header className="mb-3 text-white" section={t("Catalogo - Dettagli Esperimento")} showMenu={false} />
      {isLogged ?
        <Content active="catalog" className="pt-3">
          {renderContainer()}
        </Content>
        :
        renderContainer()


      }
    </>
  )
}